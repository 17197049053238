import numbro from "numbro";
export default function numberWithCommas  (x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function numberFormat (value,mantissa=2,negative ="sign"){

    if(value === 0) {
        return '-';
    }
    return numbro(value).format({
        thousandSeparated:true,
        mantissa:mantissa,
        negative:negative,
    })
}