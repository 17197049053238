import React from 'react';
import {VictoryLabel, VictoryTooltip} from 'victory';
import { colors } from '../../theme/theme';

export  class CustomLabel extends React.Component {
    render() {
      return (
        <g>
          {/* <VictoryLabel {...this.props}/> */}
          <VictoryTooltip
            {...this.props}
            pointerLength={0}
            flyoutStyle={{ stroke: colors.azulOscuro, strokeWidth: 1 }}
            style={{ fill: colors.rojo, padding: '5' }}
            angle={0}
          />
        </g>
      );
    }
  }

  CustomLabel.defaultEvents = VictoryTooltip.defaultEvents;
  
