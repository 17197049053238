import React, {useEffect,useState} from "react";
import {colors} from "./../../theme/theme"
import { VictoryChart,VictoryPie,VictoryLegend} from "victory"
import {numberFormat} from "./../../utils/separador-miles"

// import {div,Dimensions,label,StyleSheet} from "react-native"
// import estilos from "../../assets/estilos";
const estilos ={
    divCharts:{
      marginTop:10,
      backgroundColor:colors.azulFondoGraficos,
      
    },
  }
function PieChart ({dataPieChart,headerChart,porcentagePie}){
    console.log("dataPieChart",dataPieChart)
    return(
        <div style={estilos.divCharts}>
            <div style={{textAlign:"center",marginTop:10}}>
               <h5 style={{fontWeight:"bold"}}>{headerChart.title}</h5>
               {headerChart.entidad!=""&&<h6>{headerChart.entidad}</h6>}
                <h6>{headerChart.unidadMedida}</h6>
            </div>
        <div style={{ paddingBottom:30, backgroundColor:colors.azulFondoGraficos, margin:15}}>
        <VictoryPie
        colorScale={[colors.azulOscuro, colors.verde, colors.rojo, colors.azulClaro,colors.marron,"tomato","cyan" ,"green", colors.azulTabla, colors.marronPerla, colors.negro, colors.verdeOscuro, colors.lightYellow, colors.grisRaton, colors.lilac, colors.almond, colors.orangeSoda, colors.oldLavender, colors.blueBell, colors.champagePink]} 
        innerRadius={70}
        style={{labels: { /*margin:30,padding: 5 ,*/ fontSize: 11, fontWeight: 'bold' }}}
        data={dataPieChart.length > 0 ? dataPieChart : []}
        // width={Dimensions.get("window").width-50}
        // width={300}
        // height={300}
        style={{labels: { margin:30,padding: 5, fontSize: 12 }}}
        // labelComponent={<CustomLabel data={dataPieChart}  angle={90}/>}
        labelPlacement={"parallel"}
        padding={{ top: 50, bottom: 50 }}
         labels={({datum}) => Math.round((datum.y / porcentagePie) * 100 )>3?  Math.round((datum.y / porcentagePie) * 100 ) + '%':""}
        // labels={({datum}) => datum.y }
    />
         <div >
            <VictoryLegend 
                 colorScale={[colors.azulOscuro, colors.verde, colors.rojo, colors.azulClaro,colors.marron,"tomato","cyan" ,"green", colors.azulTabla, colors.marronPerla, colors.negro, colors.verdeOscuro, colors.lightYellow, colors.grisRaton, colors.lilac, colors.almond, colors.orangeSoda, colors.oldLavender, colors.blueBell, colors.champagePink]} 
                 centerTitle
                 itemsPerRow={6}
                 orientation="vertical"
                 gutter={40}
                 style={{ labels: { fontSize: 11, fontWeight: 'bold' }, parent: { margin: 0 } }}
                 data={dataPieChart}
                 height={120}
                 width={600}
                 x={5}
                 y={0}
                 padding={{ top: 30, bottom: 50 }}
             />
        </div>
    </div>
    </div>
    )
}
export default PieChart;