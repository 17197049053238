import { React } from 'react';
import { useLocation , NavLink } from 'react-router-dom';

import routesNavbar from "../routes/routes-main-menu";

export const Menu = () => {
    const location = useLocation();
    const activeRoute = (routeName) => {
        return location.pathname.indexOf(routeName) > -1 ? "active  " : "";
    };

    return (
        <nav className="bg-mem container" id="main-menu">
            <div className="">
                <header>
                    <ol className="my-breadcrumb rounded-0 text-white mt-3">
                        <li>
                            <span property="itemListElement" typeof="ListItem">
                                <a property="item" typeof="WebPage" title="Datos Sector Energía" href="/" className="home">
                                    <span property="name">Inicio </span>
                                </a>
                                <meta property="position" content="1" />
                            </span>  

                            <span property="itemListElement" typeof="ListItem">
                                <span property="name">
                                    {
                                        location.pathname.includes('pag') ? 
                                            location.pathname.replace('pag/','')  
                                        : 
                                        (location.pathname.includes('admin')  ? 
                                            location.pathname.replace('admin/','')  
                                        :
                                        location.pathname 
                                        )
                                        
                                       
                                    }
                                </span>
                                <meta property="position" content="3" />
                            </span>
                        </li>
                    </ol>
                </header>
                {/* <div className="d-flex wc-100">
                    <div className="d-md-none w-100">
                        <form action="#" role="search" method="get" id="searchform">
                            <input className="form-control" type="search" value="" name="s" id="s" onChange={busqueda}
                                placeholder="Ingrese su búsqueda" aria-label="Búsqueda" />
                        </form>
                    </div>
                    <div className="d-md-none">
                        <button style={{ width: '100px' }} className="navbar-toggler rounded-0" type="button" data-toggle="collapse"
                            data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="true"
                            aria-label="Toggle navigation">
                            Menú
                            <em className="ml-2 fas fa-chevron-down fa-xs"></em>
                        </button>
                    </div>
                </div> */}

                {/* <div className="navbar-collapse collapse" id="navbarNav" > */}
                    {/* <div id="main-navbar" className="collapse navbar-collapse d-none d-xs-none d-sm-none">
                        <ul id="menu-menu-principal" className="navbar-nav mr-auto">
                            {routesNavbar.map((prop, key) => {
                                if (!prop.redirect && !prop.menu_header)
                                return (
                                    <li
                                        className={
                                            activeRoute(prop.layout + prop.path) +  (prop.class === "inicio" ? prop.class : null )
                                        }
                                        key={key}
                                    >
   
                                        <NavLink
                                            to={ prop.layout + prop.path}
                                            className={ (prop.class === "inicio" ? prop.class : null ) + " nav-link" } 
                                            activeClassName="active"
                                        >
                                            {prop.name}
                                            {prop.desc ? <br/> : null}
                                            <span className="description-menu">{prop.desc}</span>
                                            <i className={ prop.icon}></i>
                                        </NavLink>
                                    </li>
                                );
                                return null;
                            })}		
                        </ul>
                    </div>	 */}

                     {/* menu móvil */}
                     {/* <div id="main-navbar" className="collapse navbar-collapse d-block d-sm-block mt-1">
                        <ul id="menu-menu-principal-movil" className="navbar-nav mr-auto d-lg-none mt-2">
                            {routesNavbar.map((prop, key) => {
                            if (!prop.redirect)
                                return (
                                    <li
                                        className={
                                            activeRoute(prop.layout + prop.path) +  'border-bottom border-light' 
                                        }
                                        key={key}
                                    >
   
                                        <NavLink
                                            to={ prop.layout + prop.path}
                                            className={ (prop.class === "inicio" ? prop.class : null ) + " nav-link" } 
                                            activeClassName="active"
                                        >
                                            {prop.name}
                                            {prop.desc ? <br/> : null}
                                            <span className="description-menu">{prop.desc}</span>
                                            <i className={ prop.icon}></i>
                                        </NavLink>
                                    </li>
                                );
                                return null;
                            })}	
                        </ul>
                    </div>	 */}
                   
                    {/* <span className="">
                        <a href="login.php" className="nav-link btn btn-login btn-info rounded-0" data-toggle="" >
                            INICIAR SESIÓN <i className="fas fa-arrow-circle-down"></i>
                        </a>
                    </span> */}
                {/* </div> */}
            </div>
        </nav>

    )

}