import { useState } from "react";

export const Costos = () => {

    const [seleccion, setTipoSeleccion] = useState([]);
    const [tipoGrafico, setTipoGrafico] = useState();

    const handleSelect = (e) => {

        const { name, value } = e.target;

        setTipoSeleccion({
            ...seleccion,
            [name]: value
        });
    }

    const handleTipoGrafico = (e) => {
        setTipoGrafico(e);
    }

    return (
        <div className="col-12 col-md-8 col-lg-9">
            <article className="content-servicios main-content">
                <header className="article-header">
                    <ol className="breadcrumb rounded-0">
                        <li>
                            <span property="itemListElement" typeof="ListItem">
                                <a property="item" typeof="WebPage" title="Datos Sector Energía" href="index.php" className="home">
                                    <span property="name">Inicio: </span>
                                </a><meta property="position" content="1" /
                                ></span> /

                            <span property="itemListElement" typeof="ListItem">
                                <span property="name">Costos</span>
                                <meta property="position" content="3" /></span>
                        </li>
                    </ol>
                </header>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-4 col-lg-4 text-center bg-charts">
                            <div className="form-group">
                                <label htmlFor="exampleFormControlSelect1">
                                    <h4>Tipo de indicador</h4>
                                </label>
                                <select className="custom-select form-control" name="indicador" onChange={handleSelect}>
                                    <option value="tt">Tipo tarifa , TT</option>
                                    <option value="tc">Tipo cliente, TC</option>
                                    <option value="s">Sector, S</option>
                                    <option value="grl">General, Grl</option>
                                </select>
                            </div>
                        </div>
                        <div className="col-4 col-lg-4 text-center bg-charts">
                            <div className="form-group">
                                <label htmlFor="exampleFormControlSelect1">
                                    <h4>Entidad</h4>
                                </label>

                                <select className="custom-select form-control" name="entidad" onChange={handleSelect}>
                                    <option value="EDESUR">EDESUR</option>
                                    <option value="EDENORTE">EDENORTE</option>
                                    <option value="EDEESTE">EDEESTE</option>
                                </select>
                            </div>
                        </div>
                        <div className="col-4 col-lg-4 bg-charts">
                            <div className="form-group">
                                <label htmlFor="exampleFormControlSelect1">
                                    <h4>Tipo de filtro</h4>
                                </label>
                                <select multiple={true} className="custom-select form-control" name="tipo-filtro" onChange={handleSelect}>
                                    <option>1</option>
                                    <option>2</option>
                                    <option>3</option>
                                    <option>4</option>
                                </select>

                                {/* <div className="d-flex flex-column">
                                    <div><input type="checkbox" value="1" onChange={handleSelect} />1</div>
                                    <div><input type="checkbox" value="2" onChange={handleSelect} />2</div>
                                    <div><input type="checkbox" value="3" onChange={handleSelect} />3</div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6 col-lg-6 text-left bg-charts">
                            <h4 className="mb-4">Rango de fecha</h4>

                            {/* <div className="d-flex flex-row">
                                <div className="form-group mr-2">
                                    <label htmlFor="exampleFormControlSelect1">
                                        <h5 className="text-center">Mes</h5>
                                    </label>

                                    <select className="custom-select form-control" name="mes" onChange={handleSelect}>
                                        <option value="Enero">Enero</option>
                                        <option value="Febrero">Febrero</option>
                                    </select>
                                </div>

                                <div className="form-group">
                                    <label htmlFor="exampleFormControlSelect1">
                                        <h5 className="text-center">Año</h5>
                                    </label>

                                    <select className="custom-select form-control" name="ano" onChange={handleSelect}>
                                        <option value="2021">2021</option>
                                        <option value="2020">2020</option>
                                        <option value="2019">2019</option>
                                    </select>
                                </div>

                                <div className="form-group ml-5 mr-2">
                                    <label htmlFor="exampleFormControlSelect1">
                                        <h5 className="text-center">mes</h5>
                                    </label>

                                    <select className="custom-select form-control" name="mes" onChange={handleSelect}>
                                        <option value="Enero">Enero</option>
                                        <option value="Febrero">Febrero</option>
                                    </select>
                                </div>

                                <div className="form-group">
                                    <label htmlFor="exampleFormControlSelect1">
                                        <h5 className="text-center">Año</h5>
                                    </label>

                                    <select className="custom-select form-control" name="ano" onChange={handleSelect}>
                                        <option value="2021">2021</option>
                                        <option value="2020">2020</option>
                                        <option value="2019">2019</option>
                                    </select>
                                </div>
                            </div> */}

                        </div>
                        <div className="col-6 col-lg-6 bg-charts text-center">
                            <h4>Elige el tipo de gráfico a mostrar</h4>
                            <div className="d-flex flex-row mb-3 mt-3 justify-content-center">
                                <div className="p-2">
                                    <a data-toggle="tooltip" data-placement="top" title="Gráfico de pastel" onClick={() => handleTipoGrafico('pie')}>
                                        <img className="img-fluid" src={process.env.PUBLIC_URL + '/img/graficos/tipo/pie-chart.png'} />
                                    </a>
                                </div>
                                <div className="p-2">
                                    <a data-toggle="tooltip" data-placement="bottom" title="Gráfico de barra" onClick={() => handleTipoGrafico('bar-chart')}>
                                        <img className="img-fluid" src={process.env.PUBLIC_URL + '/img/graficos/tipo/chart-of-columns.png'} />
                                    </a>
                                </div>
                                <div className="p-2">
                                    <a data-toggle="tooltip" data-placement="left" title="Gráfico de linea" onClick={() => handleTipoGrafico('line-chart')}>
                                        <img className="img-fluid" src={process.env.PUBLIC_URL + '/img/graficos/tipo/arrow-chart.png'} alt="grráfico de línea" />
                                    </a>
                                </div>
                                <div className="p-2 ml-2 border-left border-dark">
                                    <a data-toggle="tooltip" data-placement="right" title="Tabla" onClick={() => handleTipoGrafico('table')}>
                                        <img className="img-fluid" src={process.env.PUBLIC_URL + '/img/graficos/tipo/table.png'} alt="grráfico de tabla" />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </article>
        </div>
    )
}