import api from '../../utils/api-client'

import { endpoints, apiBase } from '../../config'

const _apiPromise = (ep) => (
    api.request({ url: `${ep}`})
);

export const getDataCatalogos = async(_endpoints) => {

//    dispatch(catalogoRequestSent())

    let endPointsAll = [];

    for (let k in _endpoints) {
        endPointsAll.push(_apiPromise(_endpoints[k]));
    }

    try {
        var results = await Promise.all(endPointsAll);
        let data = [];
        for (let k in results) {
            data[k] = results[k].data;
        }
        return data;
    } catch (error) {
        console.log("5", error);
    }
}