export const Transmision = () => {
    return (
        <div className="col-12 col-md-8 col-lg-9">
             <article className="content-servicios main-content">
                <header className="article-header">
                    <ol className="breadcrumb rounded-0">
                        <li>
                            <span property="itemListElement" typeof="ListItem">
                                <a property="item" typeof="WebPage" title="Datos Sector Energía" href="index.php" className="home">
                                    <span property="name">Inicio: </span>
                                </a><meta property="position" content="1" /
                                ></span> /

                            <span property="itemListElement" typeof="ListItem">
                                <span property="name">Transmisión</span>
                                <meta property="position" content="3" /></span>
                        </li>
                    </ol>
                </header>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12 col-lg-12 text-center bg-charts">
                           
                        </div>
                    </div>
                </div>
            </article>
        </div>
    )
}