
export const colors = {
    rojo:"#ee2a24",
    azulOscuro:"#003876",
    azulClaro:"#168dc4",
    blanco: "#ffff",
    negro:"#000",
    verdeOscuro:"#375623",
    verde:"#ddfac9",
    azulTabla:"#0070C0",
    marron:"#AEAAAA",
    azulFondoGraficos:"#F2FAFC",
    grisInvertido:"#646464",
    marronPerla:"#755c48",
    lightYellow: '#f6feaa',
    grisRaton: '#373737',
    lilac: '#C49BBB',
    orangeSoda: '#F55D3E',
    almond: '#DCCCBB',
    oldLavender: '#5F5566',
    blueBell: '#9D96B8',
    champagePink: '#D0CEBA'
}

export const ThemeColors = {
    primaryText: {
        light: 'black',
        dark: 'white',
    },
    primaryBackground: {
        light: '#2994C3',
        dark: '#0c1f3f',
    },
    secondaryText: {
        light: 'white',
        dark: 'black',
    },
    secondaryBackground: {
        light: 'white',
        dark: 'black',
    },
    inputTextTheme:{
        light:{
            colors:{
            placeholder:colors.negro,
            primary: colors.azulOscuro,
            underlineColor:'transparent',
            background:colors.blanco,
            backdrop:colors.blanco,
            disabled:colors.blanco,
            text:colors.negro
        }},
        dark:{
            colors:{
            placeholder:colors.blanco,
            primary: colors.blanco,
            underlineColor:'transparent',
            background:colors.negro,
            backdrop:colors.negro,
            disabled:colors.negro,
            text:colors.blanco
        }}
    }
};


