import { useState, useEffect, useLayoutEffect, useRef } from "react";
import {VictoryBar, VictoryChart, VictoryLegend, VictoryLabel ,VictoryLine , VictoryTheme, VictoryTooltip ,VictoryAxis, VictoryPie} from 'victory';

import { Multiselect } from 'multiselect-react-dropdown';
import moment, { monthsShort } from "moment";
import 'moment/locale/es';
import { CustomLabel } from './CustomLabel';

import {colors} from "../../theme/theme";
import {numberFormat} from "../../utils/separador-miles";
import { endpoints } from "../../config";

import { getDataCatalogos } from '../../redux/ducks/comercializacion';

// syncfusion react dropdowns
import {CheckBoxSelection, Inject,  MultiSelectComponent } from '@syncfusion/ej2-react-dropdowns';
import "./../../../node_modules/@syncfusion/ej2-base/styles/material.css";
import "./../../../node_modules/@syncfusion/ej2-inputs/styles/material.css";
import "./../../../node_modules/@syncfusion/ej2-react-dropdowns/styles/material.css";
import "./../../../node_modules/@syncfusion/ej2-buttons/styles/material.css";

import swal from 'sweetalert';


export const DistribuccionComercializacion = () => { 

    const [seleccion, setTipoSeleccion] = useState([]);
    const [tipoGrafico, setTipoGrafico] = useState('bar-chart');
    //const [test, setTest] = useState([]);

    const [indicadores, setIndicadores] = useState([]);
    
    const [listadoCompInidcadores ,setListadoCompIndicadores] = useState([]);
    const [indicadorSeleccionadoViejo, setIndicadorSeleccionadoViejo] = useState(0);
    const [initialLoading, setInitialLoading] = useState(false);
    const [Loading, setLoading] = useState(false);

    const [indicadoresMostrados, setIndicadoresMostrados] = useState([]);

    const [indicadoresCalculadosDescendencia, setIndicadoresCalculadosDescencia] = useState([]);
    const [indicadoresCalculadosRelacionados, setIndicadoresCalculadosRelacionados] = useState([]);
    const [listadoIndRelacionados, setListadoIndRelacionados] = useState([]);

    const [dataChart, seDataChart] = useState([]);
    const [dataPieChart, seDataPieChart] = useState([]);
    const [porcentagePie, setPorcentagePie] = useState([]);
    const [dataLineChart, seDataLineChart] = useState([]);
    const [dataTable, setDataTable] = useState([]);

    const [indicadorLeyendaLineChart, setIndicadorLeyendaLineChart] = useState([]);

    const [unidadMedida, setUnidadMedida] = useState('');

    const [entidades, setEntidades] = useState([]);
    //const [tipoIndicadores, setTipoIndicadores] = useState([]);
    const [periodos, setPeriodos] = useState([]);

    const [listadoPeriodos, setListadoPeriodos] = useState([]);

    const [indicadorSeleccionado, setTipoIndicadorSeleccionado] = useState('');

    const [desagregacion, setDesagregacion] =useState(false);
    const [relacionados, setRelacionados] = useState(false);

    const [mostrarGrafico, setMostrarGrafico] = useState([]);

    let indColorsLeyenda = [];

    let mulObj = useRef(null); // ref => { current: null }

    // map the groupBy field with category column
    let fields = { groupBy: 'ano', text: 'mesNombre', value: 'id' };

    let fieldsComparacion = { groupBy: 'categoria', text: 'indicador', value: 'id' };
    
    const handleCategoriasSelect = (e) => {
        const { name, value } = e.target;
        let im = [];
        indicadores[0].data.map((e) => {
            if ( e.categoriaId == value ) {
                im.push(e);
            }
        });
        setIndicadoresMostrados(im);
    }

    const handleSelect = (e) => {
        const { name, value } = e.target;

        setTipoSeleccion({
            ...seleccion,
            [name]: parseInt(value),
        });

        if(name === "indicador") {
            let index = e.nativeEvent.target.selectedIndex;
            let indicadorSel = e.nativeEvent.target[index].text;

            setTipoIndicadorSeleccionado(indicadorSel);
        }
    }

    const tipoGraficoIndicadores = [
        {
            indicador: 1,
            tipo_grafico: ['linea', 'barra']
        },
        {
            indicador: 2,
            tipo_grafico: ['linea', 'barra']
        },
        {
            indicador: 3,
            tipo_grafico: ['linea']
        },
        {
            indicador: 4,
            tipo_grafico: ['linea']
        },
        {
            indicador: 5,
            tipo_grafico: ['linea']
        },
        {
            indicador: 6,
            tipo_grafico: ['linea']
        },
        {
            indicador: 7,
            tipo_grafico: ['linea', 'barra']
        },
        {
            indicador: 8,
            tipo_grafico: ['linea', 'barra']
        },
        {
            indicador: 9,
            tipo_grafico: ['linea', 'barra']
        },
        {
            indicador: 10,
            tipo_grafico: ['linea', 'barra']
        },
        {
            indicador: 11,
            tipo_grafico: [ 'barra']
        },
        {
            indicador: 12,
            tipo_grafico: ['linea', 'barra']
        },
        {
            indicador: 13,
            tipo_grafico: ['linea', 'barra']
        },
        {
            indicador: 14,
            tipo_grafico: ['linea', 'barra']
        },
        {
            indicador: 15,
            tipo_grafico: ['linea', 'barra']
        },
        {
            indicador: 16,
            tipo_grafico: ['linea']
        },
        {
            indicador: 17,
            tipo_grafico: ['linea']
        },
        {
            indicador: 18,
            tipo_grafico: ['linea']
        },
        {
            indicador: 19,
            tipo_grafico: ['linea']
        },
        {
            indicador: 20,
            tipo_grafico: ['barra']
        },
        {
            indicador: 21,
            tipo_grafico: ['barra']
        },
        {
            indicador: 22,
            tipo_grafico: ['barra']
        },
        {
            indicador: 23,
            tipo_grafico: ['barra']
        },
        {
            indicador: 24,
            tipo_grafico: ['barra']
        },
        {
            indicador: 25,
            tipo_grafico: ['barra']
        },
        {
            indicador: 26,
            tipo_grafico: ['linea']
        },
        {
            indicador: 27,
            tipo_grafico: ['linea']
        },
        {
            indicador: 28,
            tipo_grafico: ['linea']
        },
        {
            indicador: 29,
            tipo_grafico: ['barra']
        },
        {
            indicador: 30,
            tipo_grafico: ['barra']
        },
        {
            indicador: 31,
            tipo_grafico: ['linea']
        },
        {
            indicador: 32,
            tipo_grafico: ['barra']
        },
        {
            indicador: 33,
            tipo_grafico: ['barra']
        },
        {
            indicador: 34,
            tipo_grafico: ['barra']
        },
        {
            indicador: 35,
            tipo_grafico: ['linea', 'barra']
        },
        {
            indicador: 36,
            tipo_grafico: ['linea', 'barra']
        },
        {
            indicador: 37,
            tipo_grafico: ['linea', 'barra']
        },
        {
            indicador: 38,
            tipo_grafico: ['barra']
        },
        {
            indicador: 39,
            tipo_grafico: ['barra']
        },
        {
            indicador: 40,
            tipo_grafico: ['barra']
        },
        {
            indicador: 41,
            tipo_grafico: ['barra']
        },
        {
            indicador: 42,
            tipo_grafico: ['barra']
        },
        {
            indicador: 43,
            tipo_grafico: ['linea']
        },

    ]

    let graficoLinea = false;
    let graficoBarra = false;
    let graficoPie = false;
    let graficoTabla = false;

    const lineChartGeneration = (data) => {

        let lineChart = [];

        data.forEach(item => {
            lineChart = [...lineChart, {
                name: item.indicadorCalculadoId,
                x: `${moment(`${item.ano}-${item.mes}-12`).format("MMM-YYYY")}` ,
                y: item.valor,
                placement: `${item.valor}`,
            }];
        });
        
        return lineChart;
           
    }

    const randonColors = (indicador) => {
        
        let colorsArr = [colors.grisRaton, colors.verde, colors.rojo, colors.azulClaro, colors.marron, colors.azulTabla, colors.champagePink, colors.blueBell, colors.marronPerla, colors.verdeOscuro];
        // let positionColor = Math.floor(Math.random() * (colorsArr.length - indicador)) + indicador;
        let positionColor = Math.floor(Math.random() * colorsArr.length);

        let position = colorsArr[indicador + 1];

        console.log(position)

        return position;
    }
    
    const handleTipoGrafico = (e) => {
        setTipoGrafico(e);
    }

    const onSelect = (args) => {

        let p = [];
       
        p = args.value;
        setListadoPeriodos(p );

    }

    const onSelectCompIndicadores = (args) => {

        let ind = [];
       
        ind = args.value;
        setListadoCompIndicadores(ind);

        setTipoSeleccion({
            ...seleccion,
            indicador_relacionado : [args.value],
        });

    }

    useEffect( () => {
        if(indicadoresCalculadosRelacionados.length > 0){
            handleTipoGrafico('line-chart');
            let indCalcRelacionados = [];

            if(indicadoresCalculadosRelacionados && indicadoresCalculadosRelacionados !== [])  {

                indicadoresCalculadosRelacionados.map( (i) => {
                    indCalcRelacionados = [...indCalcRelacionados, {
                        id: i.id,
                        indicador: i.indicador + ' (' + i.leyenda + ')',
                        categoria: i.categoria
                    } ];
    
                    setListadoIndRelacionados(indCalcRelacionados);
    
                });
            }
        }
        
    },[indicadoresCalculadosRelacionados]);

    useEffect( () => {
        if(indicadoresCalculadosDescendencia.length > 0){
            handleTipoGrafico('pie-chart');
        }
    },[indicadoresCalculadosDescendencia]);

    useEffect( () => {

        let indCalculadosRelacionados = [];
        let indCalculadosDescendencias = [];
        let indicador = indicadoresMostrados.filter( x => x.id == seleccion.indicador);

        let TipoGrafico = tipoGraficoIndicadores.filter( x => x.indicador == seleccion.indicador);

        setMostrarGrafico(TipoGrafico);

        if( indicador.length > 0 && indicador[0].indicadoresCalculadosRelacionados) {
            indicador[0].indicadoresCalculadosRelacionados.forEach(indicadorRelacionadoId => {

                let indicadorRelacionado = indicadores[0].data.filter( y => y.id == indicadorRelacionadoId.id);

                indCalculadosRelacionados = [...indCalculadosRelacionados , indicadorRelacionado[0]];
            });

            setIndicadoresCalculadosRelacionados(indCalculadosRelacionados);

        } 
        
        if (indicador.length > 0 && indicador[0].indicadoresCalculadosDescendencias.length > 0 && indicador[0].hasIndicadoresCalculadosDecendencia) {
            indicador[0].indicadoresCalculadosDescendencias.forEach(indicadorDescendenteId => {

                indCalculadosDescendencias = [...indCalculadosDescendencias , indicadorDescendenteId];

            });
            setIndicadoresCalculadosDescencia(indCalculadosDescendencias);
        }


    },[seleccion.indicador]);

    useEffect( () => {
        
        graficoLinea = false;
        graficoBarra = false;
        graficoTabla =  false;
    },[seleccion.indicador_descendiente]);

    var sum = 0;
    const totalValor = (valor) => {
        sum += valor;
    }   

    const genChart = () => {
       if(seleccion.indicador && tipoGrafico) {

            var pId = [];
            let barChart = [];
            let lineChart = [];
            let pieChart = [];
            let indId = []; 

            if(listadoPeriodos.length === 0){
                swal("Debe de elegir al menos un período");
                return false;
            }
            
            else{
                switch(tipoGrafico) {
                    case 'bar-chart':
                        listadoPeriodos.map( (periodo, key) => {
                            pId +=  `&periodo[${key}]=${periodo}`
                        });

                        if(listadoPeriodos.length == 1){
                            swal("Debe de elegir más de un periodo");
                        }
    
                        getDataCatalogos([`${endpoints.comercial_valores}?indicadorCalculadoId=${seleccion.indicador}&entidadId=${seleccion.entidad}${pId}`]).then((data) => {
                            setInitialLoading(true);      
                            
                            setUnidadMedida(data[0].data.leyenda);
            
                            data[0].data.valoresIndicadoresCalculados.map( (data , key) => {
                                if(data){
                                    barChart = [...barChart, {
                                        name:`${data.valor}: ${numberFormat(data.valor)}`,
                                        x: `${moment(`${data.ano}-${data.mes}-12`).format("MMM-YYYY")}`  ,
                                        y: data.valor,
                                    } ]
                                }
                                return true;
                            });
            
                            seDataChart(barChart);
                            setLoading(true);
                        });
                        
                      break;
                    case 'line-chart':
                        listadoPeriodos.map( (periodo, key) => {
                            pId +=  `&periodo[${key}]=${periodo}`
                        });

                        listadoCompInidcadores.map( (indicador, key) => {
                            indId += `&indicadorCalculadoRelacionados[${key}]=${indicador}`;
                        });

                        if(listadoPeriodos.length == 1){
                            swal("Debe de elegir más de un periodo");
                        }

                        setIndicadorLeyendaLineChart([]);
                        if(seleccion.indicador_relacionado){

                            if(listadoPeriodos.length === 1){
                                swal("Debes elegir más de un periodo para ver el gráfico de línea");
                                return false;
                            }

                            getDataCatalogos([`${endpoints.comercial_valores_calculados_relacionados}?indicadorCalculadoId=${seleccion.indicador}${indId}&entidadId=${seleccion.entidad}&${pId}`]).then((data) => {
                                setInitialLoading(true);       
                                let variable  = [];
                                setUnidadMedida(data[0].data[0].leyenda);

                                data[0].data.forEach( (element, key) => {
                                    let color=  randonColors(key);

                                    if(variable.length > 0 && variable[0].colorFill === color) {
                                        color = randonColors(key);
                                    }
                                    variable = [...variable,{...element,colorFill:color}];

                                    indColorsLeyenda = [ ...indColorsLeyenda, {
                                        name:element.indicador,
                                        symbol: { fill: color }
                                    }];

                                });
                   
                                setIndicadorLeyendaLineChart(
                                    indColorsLeyenda
                                );

                                seDataLineChart(variable);
                                setLoading(true);
                            })
                            
                        }else{
                            getDataCatalogos([`${endpoints.comercial_valores}?indicadorCalculadoId=${seleccion.indicador}&entidadId=${seleccion.entidad}${pId}`]).then((data) => {
                                setInitialLoading(true);       
                                
                                setUnidadMedida(data[0].data.leyenda);
                              
                                seDataLineChart( [data[0].data] );
                                setLoading(true);
                                setTipoGrafico('linea')
                            });
                        }
                   
                      break;
                    case 'pie-chart':

                        listadoPeriodos.map( (periodo, key) => {
                            pId +=  `&periodo[${key}]=${periodo}`
                        });

                        if(listadoPeriodos.length == 1 && seleccion.indicador_descendiente == undefined) {
                            swal("Debe de elegir más de un periodo y elegir el indicador descendiente");
                            return false;
                        }
    
                        if(indicadoresCalculadosDescendencia.length > 0) {
                            getDataCatalogos([`${endpoints.comercial_indicador_calculado_descendencias}?indicadorCalculadoId=${seleccion.indicador}&tipoIndicadorId=${seleccion.indicador_descendiente}&entidadId=${ seleccion.entidad  ? seleccion.entidad : 0 }${pId}`]).then((data) => {
                                setInitialLoading(true);      
                                
                                setUnidadMedida(data[0].data.unidadMedida);

                                data[0].data.valoresIndicadorCalculadosDescendencia.map( (data , key) => {
                                    if(data){
                                        pieChart = [...pieChart, {
                                            name: `${data.catalogoNombre}`,
                                            x: data.valor, 
                                            y: data.valor,
                                            placement: `${data.valor}% `
                                        } ]
                                    }
                                    return true;
                                });
                                
                                let sum = 0;
                                pieChart.map( (pie) => {
                                    sum += parseInt(pie.y);
                                });

                                setPorcentagePie(sum)
                                seDataPieChart(pieChart);

                                setLoading(true);
                            });
                        }else{
                            swal('No se puede generar gráfico de pastel, debe de tener un indicador de descendencia.');
                        }
                      break;
                    case 'table':
                        listadoPeriodos.map( (periodo, key) => {
                            pId +=  `&periodo[${key}]=${periodo}`
                        });
    
                        getDataCatalogos([`${endpoints.comercial_valores}?indicadorCalculadoId=${seleccion.indicador}&entidadId=${seleccion.entidad}${pId}`]).then((data) => {
                            setInitialLoading(true);      
                            
                            setUnidadMedida(data[0].data.leyenda);
            
                            setDataTable(data[0].data.valoresIndicadoresCalculados);
                            setLoading(true);
                        });
                      break;
                    default:
                      // code block
                }
            }

       }
    }
 
    useEffect(() => {
        if ( !initialLoading ) {
            getDataCatalogos([endpoints.comercial_indicadores, endpoints.comercial_periodos, endpoints.comercial_entidades]).then((data) => {
                setInitialLoading(true);                
                setIndicadores(data);
            });
        }

    }, []);

    useEffect(() => {
        let indicadoresPeriodos = [];

        if(indicadores[1] && indicadores[1] !== [])  {
            indicadores[1].data.map( (i) => {

                indicadoresPeriodos = [...indicadoresPeriodos, {
                    id: i.id,
                    ano: i.ano,
                    mes: i.mes,
                    mesNombre: moment(`2021-${i.mes}-12`).format("MMMM"),
                    estado: i.estado
                } ];

                moment.locale('es');

                setPeriodos(indicadoresPeriodos);

            });
        }
    },[indicadores]);

    useEffect( () => {
        
        if(listadoPeriodos.length === 1){
            setDesagregacion(true);
            setRelacionados(false);
        }
        else if(listadoPeriodos.length > 1){
            setRelacionados(true);
            setDesagregacion(false);
        }
    },[listadoPeriodos]);

    return (
        <div className="col-12 col-md-8 col-lg-9">
            <article className="content-servicios main-content">
                {/* <header className="article-header">
                    <ol className="breadcrumb rounded-0">
                        <li>
                            <span property="itemListElement" typeof="ListItem">
                                <a property="item" typeof="WebPage" title="Datos Sector Energía" href="index.php" className="home">
                                    <span property="name">Inicio: </span>
                                </a><meta property="position" content="1" /
                                ></span> /

                            <span property="itemListElement" typeof="ListItem">
                                <span property="name">Distribucción y comercialización</span>
                                <meta property="position" content="3" />
                            </span>
                        </li>
                    </ol>
                </header> */}
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-3 text-center bg-charts">
                            <div className="form-group">
                                <h5>Categoría</h5>
                                <select className="custom-select form-control" name="indicador" onChange={handleCategoriasSelect}>
                                    <option value="">- Seleccione -</option>
                                    <option value="1">Compras</option>
                                    <option value="2">Facturación</option>
                                    <option value="3">Cobranza</option>
                                    <option value="4">Clientes</option>
                                    <option value="5">Deuda</option>
                                    <option value="6">Otros</option>
                                </select>
                            </div>
                        </div>

                        <div className="col-lg-3 text-center bg-charts">
                            <div className="form-group">
                                <h5>Indicador</h5>
                                
                                <select className="custom-select form-control" name="indicador" onChange={handleSelect}>
                                    <option>- seleccione -</option>
                                    {indicadoresMostrados.map( (e) => {
                                        return <option value={e.id}>{e.indicador} ( {e.leyenda} )</option>
                                    })}
                                </select>
                            </div>
                        </div>
                        
                        <div className="col-lg-3 bg-charts">
                            <div className="form-group">
                                <h5>Periodo</h5>
                                <MultiSelectComponent 
                                    id="mtselement" 
                                    popupHeight='400px' 
                                    fields={fields} 
                                    dataSource={periodos} 
                                    placeholder="Selecciona un período" 
                                    mode="CheckBox" 
                                    enableGroupCheckBox="true" 
                                    allowFiltering="true" 
                                    showSelectAll="true" 
                                    filterBarPlaceholder="Buscar períodos"
                                    ref={(scope) => { mulObj = scope; }} 
                                    change={onSelect.bind(this)}
                                    // select={onSelect.bind(this)}
                                    >
                                    <Inject services={[CheckBoxSelection]} />
                                </MultiSelectComponent>
                            </div>
                        </div>

                        
                        <div className="col-lg-3 bg-charts">
                                {indicadoresCalculadosRelacionados.length > 0 && relacionados ?  
                                    <div className="form-group">
                                        <h5>Comparar indicador</h5>
                                        <MultiSelectComponent 
                                            id="mtselement" 
                                            name="indicador_relacionado"
                                            popupHeight='400px' 
                                            fields={fieldsComparacion} 
                                            dataSource={listadoIndRelacionados} 
                                            placeholder="Selecciona un indicador" 
                                            mode="CheckBox" 
                                            enableGroupCheckBox="true" 
                                            allowFiltering="true" 
                                            showSelectAll="true" 
                                            filterBarPlaceholder="Buscar períodos"
                                            ref={(scope) => { mulObj = scope; }} 
                                            change={onSelectCompIndicadores.bind(this)}
                                            // select={onSelect.bind(this)}
                                        >
                                        <Inject services={[CheckBoxSelection]} />
                                        </MultiSelectComponent>
                                    </div>
                                : null
                                }

                                {indicadoresCalculadosDescendencia.length > 0  && desagregacion ? 
                                     
                                    <div className="form-group">
                                        <h5>Desagregación</h5>
                                        <select className="custom-select form-control" name="indicador_descendiente" onChange={handleSelect} >
                                            <option value="0">- seleccione -</option>
                                            {indicadoresCalculadosDescendencia.map( (i) => {
                                                return <option value={i.tipoIndicador.id}> Por {i.tipoIndicador.nombre}</option>
                                            })}
                                        </select>
                                    </div>
                                
                                :  null
                                }
                        </div>
                        
                        {/* <div className="col-2 col-lg-2 bg-charts">
                            { indicadoresCalculadosDescendencia.length > 0 ? 
                                <div className="form-group">
                                    <label htmlFor="exampleFormControlSelect1">
                                        <h4>Desagregación</h4>
                                    </label>
                                    <select className="custom-select form-control" name="indicador_descendiente" onChange={handleSelect} >
                                        <option>- seleccione -</option>
                                        {indicadoresCalculadosDescendencia.map( (i) => {
                                            return <option value={i.tipoIndicador.id}> Por {i.tipoIndicador.nombre}</option>
                                        })}
                                    </select>
                                </div>
                            :  null}
                        </div> */}
                    </div>

                    <div className="row">
                        <div className="col-sm-12 col-lg-5 text-center bg-charts">
                            <div className="form-group">
                                <h5>Empresa</h5>
                                <select className="custom-select form-control" name="entidad" onChange={handleSelect}>
                                    <option value="0">Todos</option>
                                    {indicadores[2] && indicadores[2].data.map( (e) => {
                                        return <option value={e.id}>{e.nombre}</option>
                                    })}
                                </select>
                            </div>
                        </div>

                        <div className="col-sm-12 col-lg-7 bg-charts text-center">
                            <h5>Ver como</h5>
                            {
                                mostrarGrafico.map( (item, key) => {
                                        item.tipo_grafico.map( (item) => {
                                            switch(item){
                                                case 'linea' :
                                                    graficoLinea = true;
                                                break;
                                                case 'barra' :
                                                    if(seleccion.indicador_relacionado !== [] && seleccion.indicador_relacionado !== undefined && seleccion.indicador_relacionado[0].length){
                                                        graficoBarra = false;
                                                    }else{
                                                        graficoBarra = true;
                                                    }
                                                break;
                                                case 'pastel' :
                                                    graficoPie = true;
                                                break;
                                                case 'tabla' :
                                                    graficoTabla = true;
                                                break;

                                                default:
                                                    //
                                                    graficoLinea = false;
                                                    graficoBarra = false;
                                                    graficoTabla =  false;

                                            }

                                        })
                                })

                            }

                            <div className="d-flex flex-row mb-3 mt-3 justify-content-center align-items-center">
                                {
                                    graficoPie || seleccion.indicador_descendiente !== undefined ? 

                                        <div className={ seleccion.indicador_descendiente == 0 ? ' d-none' : ( tipoGrafico === 'pie-chart'  ? 'p-2 border border-primary bg-info' : 'p-2') }>
                                            <a data-toggle="tooltip" data-placement="top" title="Gráfico de pastel" onClick={() => handleTipoGrafico('pie-chart')}>
                                                <img className={tipoGrafico === 'pie-chart' ? 'img-fluid hover-filter-chart': 'img-fluid'} src={process.env.PUBLIC_URL + '/img/graficos/tipo/pie-chart.png'} />
                                            </a>
                                        </div>
                                    :  null
                                }
                                
                                {
                                    graficoBarra && !seleccion.indicador_descendiente  ? 
                                    
                                        <div className={tipoGrafico === 'bar-chart' ? 'p-2 border border-primary bg-info'  : 'p-2' }>
                                            <a data-toggle="tooltip" data-placement="bottom" title="Gráfico de barra" onClick={() => handleTipoGrafico('bar-chart')}>
                                                <img className={tipoGrafico === 'bar-chart' ? 'img-fluid hover-filter-chart': 'img-fluid'} src={process.env.PUBLIC_URL + '/img/graficos/tipo/chart-of-columns.png'} />
                                            </a>
                                        </div>
                                    : null
                                }
                               
                               
                               {
                                    graficoLinea && !seleccion.indicador_descendiente  ?
                                    <div className={tipoGrafico === 'line-chart' ? 'p-2 border border-primary bg-info' :   'p-2' }>
                                        <a data-toggle="tooltip" data-placement="left" title="Gráfico de linea" onClick={() => handleTipoGrafico('line-chart')}>
                                            <img className={tipoGrafico === 'line-chart' ? 'img-fluid hover-filter-chart': 'img-fluid'} src={process.env.PUBLIC_URL + '/img/graficos/tipo/arrow-chart.png'} alt="grráfico de línea" />
                                        </a>
                                    </div>
                                    : null
                                }

                                {
                                    graficoTabla && seleccion.indicador_descendiente  ? 
                                        <div className={tipoGrafico === 'table' ? 'p-2 border border-primary p-2 ml-2 border-left border-dark bg-info' : 'p-2'}>
                                            <a data-toggle="tooltip" data-placement="right" title="Tabla" onClick={() => handleTipoGrafico('table')}>
                                                <img className={tipoGrafico === 'table' ? 'img-fluid hover-filter-chart': 'img-fluid'} src={process.env.PUBLIC_URL + '/img/graficos/tipo/table.png'} alt="grráfico de tabla" />
                                            </a>
                                        </div>
                                    : null
                                }
                               
                                <div className="p-2 ml-2 border-left border-info">
                                    <button onClick={() => genChart()} className="ml-2 btn btn-secondary-mem rounded-0 font-weight-bold">Filtrar 
                                        <i className="ml-2 fas fa-search fa-lg"></i>
                                    </button>
                                </div>
                               
                            </div>
                        </div>
                    </div>

                    
                    { 
                        dataChart.length > 0 || dataLineChart.length > 0 || dataPieChart.length > 0 || dataTable.length > 0   
                    ?
                        <div className="row bg-charts pb-6 mb-3">
                            <div className="col-12 text-center p-3">
                                {Loading ? 
                                    <>
                                        <h5 className=" text-uppercase p-2">
                                            {indicadorSeleccionado}
                                        </h5>
                                        <h6>
                                        {indicadores[2] && indicadores[2].data.map( (e) => {
                                            if(seleccion.entidad == e.id){
                                                return e.nombre;
                                            }
                                         })}
                                         </h6>
                                        <h6>Datos en : ({unidadMedida})</h6>
                                        {tipoGrafico === 'bar-chart' & dataChart.length > 0 ?
                                            <VictoryChart
                                                // theme={VictoryTheme.material}
                                                domainPadding={{ x: 100 }}
                                                padding={{ left: 70, right: 50, top: 50, bottom: 50 }}
                                                height={350} width={700}
                                                style={{
                                                    labels: {
                                                        fontSize: '10'
                                                    }
                                                }}
                                                animate={{duration: 500}}
                                            >
                                                <VictoryBar
                                                    //  barWidth={({ index }) => index * 2 + 8}
                                                    barRatio={0.9}
                                                    labels={({ datum }) => `${numberFormat(datum.y) }`}
                                                    colorScale={[colors.azulOscuro, colors.verde, colors.rojo, colors.azulClaro, colors.marron, "tomato", "cyan", colors.azulTabla, colors.azulClaro]}
                                                    style={{
                                                        labels: {  fontSize: '10', fontWeight: 'bold' },
                                                        data: { fill: colors.verdeOscuro }
                                                    }}
                                                    data={dataChart}
                                                    labelComponent={<CustomLabel data={dataChart}/>}
                                                />
                                            </VictoryChart>

                                            : null
                                        }
                                        
                                    </>
                                    :
                                    null
                                }

                                {
                                    tipoGrafico === 'line-chart' && dataLineChart.length > 0 ?
                                        
                                    <div className="flex flex-row">
                                            
                                        <div className="mt-4">
                                            {
                                                seleccion.indicador_relacionado ?
                                                    <VictoryLegend 
                                                        // colorScale={[colors.azulOscuro, colors.verde, colors.rojo, colors.azulClaro,colors.marron,"tomato","cyan" ,"green", colors.azulTabla, colors.marronPerla, colors.negro, colors.verdeOscuro, colors.lightYellow, colors.grisRaton, colors.lilac, colors.almond, colors.orangeSoda, colors.oldLavender, colors.blueBell, colors.champagePink]} 
                                                        x={20}
                                                        y={0}
                                                        height={100}
                                                        itemsPerRow={2}
                                                        title="Leyenda"
                                                        centerTitle
                                                        orientation="horizontal"
                                                        gutter={20}
                                                        style={{ title: {fontSize: 10 } }}
                                                        data={indicadorLeyendaLineChart}
                                                        width={500}
                                                        // orientation="vertical"
                                                    />
                                                :
                                                null

                                            }
                                            
                                        </div>

                                        <div className="">
                                            <VictoryChart   
                                                // minDomain={0}
                                                theme={VictoryTheme.material}
                                                padding={{ left: 90, right: 50, top: 30, bottom: 100 }}
                                                domainPadding={{ x: 30 }}
                                                minDomain={{ y: 0 }}
                                                // minDomain={0}
                                                // domain={{ y: [0, 500] }}
                                                height={350} 
                                                width={800}
                                                animate={{duration: 500}}>
                                                {
                                                    dataLineChart.map( (data) => {
                                                        return (
                                                            <VictoryLine
                                                                style={{
                                                                    data: { stroke:data.colorFill }
                                                                }}
                                                                data={lineChartGeneration(data.valoresIndicadoresCalculados)}
                                                                labels={({datum}) =>  ( Math.round( (datum.y + Number.EPSILON) * 100 ) ) / 100   }
                                                                labelComponent={<VictoryLabel renderInPortal/>} 
                                                            />
                                                        )
                                                    })
                                                }
                                            </VictoryChart>
                                        </div>
                                    </div>
                                
                                : null
                                }

                                {
                                    tipoGrafico === 'pie-chart' & dataPieChart.length > 0 ?

                                    <>
                                        <div className="d-flex flex-column p-3">
                                            <div className="p-0">
                                                <VictoryPie
                                                    colorScale={[colors.azulOscuro, colors.verde, colors.rojo, colors.azulClaro,colors.marron,"tomato","cyan" ,"green", colors.azulTabla, colors.marronPerla, colors.negro, colors.verdeOscuro, colors.lightYellow, colors.grisRaton, colors.lilac, colors.almond, colors.orangeSoda, colors.oldLavender, colors.blueBell, colors.champagePink]} 
                                                    innerRadius={70}
                                                    style={{labels: { /*margin:30,padding: 5 ,*/ fontSize: 11, fontWeight: 'bold' }}}
                                                    data={dataPieChart.length > 0 ? dataPieChart : []}
                                                    width={800}
                                                    height={400}
                                                    labelComponent={<CustomLabel data={dataPieChart}  angle={90}/>}
                                                    padding={{ top: 50, bottom: 0 }}
                                                    labels={({datum}) =>  Math.round((datum.y / porcentagePie) * 100 ) + '%'}
                                                    labels={({datum}) => datum.y }
                                                />
                                            </div>
                                            <div className="p-0">
                                                <VictoryLegend 
                                                    colorScale={[colors.azulOscuro, colors.verde, colors.rojo, colors.azulClaro,colors.marron,"tomato","cyan" ,"green", colors.azulTabla, colors.marronPerla, colors.negro, colors.verdeOscuro, colors.lightYellow, colors.grisRaton, colors.lilac, colors.almond, colors.orangeSoda, colors.oldLavender, colors.blueBell, colors.champagePink]} 
                                                    centerTitle
                                                    itemsPerRow={6}
                                                    orientation="vertical"
                                                    gutter={40}
                                                    style={{ labels: { fontSize: 11, fontWeight: 'bold' }, parent: { margin: 0 } }}
                                                    data={dataPieChart}
                                                    height={120}
                                                    width={600}
                                                    x={5}
                                                    y={0}
                                                    padding={{ top: 30, bottom: 50 }}
                                                />
                                            </div>
                                        </div>
                                    </>

                                : null
                                }


                                { tipoGrafico === 'table' && dataTable.length > 0 ? 
                                <div className="bg-white">
                                    <table className="table table-bordered">
                                        {
                                            dataTable.map( (item, index) => {
                                                return(
                                                    <tr key={index}>
                                                        <td>{`${moment(`${item.ano}-${item.mes}-12`).format("MMM-YYYY")}`}</td>
                                                        <td>  {numberFormat(item.valor)}   </td>
                                                    </tr>
                                                )
                                                
                                            })
                                        }
                                    </table>
                                </div>
                                :  null
                                }
                            
                            </div>
                        
                        </div>
                    : null
                
                    }

                
                    {/* end row */}



                </div>
            </article>
        </div>
    )
}