import { useState, useEffect, useLayoutEffect, useRef } from "react";
import {
  VictoryBar,
  VictoryChart,
  VictoryLegend,
  VictoryLabel,
  VictoryLine,
  VictoryTheme,
  VictoryTooltip,
  VictoryAxis,
  VictoryPie,
} from "victory";

import { Multiselect } from "multiselect-react-dropdown";
import moment, { monthsShort } from "moment";
import "moment/locale/es";
import { CustomLabel } from "./CustomLabel";

import { colors } from "../../theme/theme";
import { numberFormat } from "../../utils/separador-miles";
import { endpoints, meses, tipoGraficoIndicadores } from "../../config";

import { getDataCatalogos } from "../../redux/ducks/comercializacion";

// syncfusion react dropdowns
import {
  CheckBoxSelection,
  Inject,
  MultiSelectComponent,
} from "@syncfusion/ej2-react-dropdowns";
import "./../../../node_modules/@syncfusion/ej2-base/styles/material.css";
import "./../../../node_modules/@syncfusion/ej2-inputs/styles/material.css";
import "./../../../node_modules/@syncfusion/ej2-react-dropdowns/styles/material.css";
import "./../../../node_modules/@syncfusion/ej2-buttons/styles/material.css";
import { LineChart, BarChart, PieChart } from "./../charts";
import Loading from "./../Loading";
import swal from "sweetalert";
import { sort } from "fast-sort";

export const Finanzas = () => {
  const [entidadSelected, setEntidadSelected] = useState(0);
  const [indicadorSelected, setIndicadorSelected] = useState(0);
  const [indicadoresList, setIndicadoresList] = useState([]);
  const [chartSelected, setChartSelected] = useState("");
  const [chartLine, setCharLine] = useState(false);
  const [chartBar, setChartBar] = useState(false);
  const [chartPie, setChartPie] = useState(false);
  const [maxValueY, setMaxValueY] = useState(500);
  const [minValueY, setMinValueY] = useState(100);
  const [headerChart, setHeaderChart] = useState({});
  const [valoresIndicadores, setValoresIndicadores] = useState([]);
  const [valoresIndicadoresDesagregados, setValoresIndicadoresDesagregados] =
    useState([]);
    const [seleccion, setTipoSeleccion] = useState([]);


    const [indicadores, setIndicadores] = useState([]);
  
  
    const [initialLoading, setInitialLoading] = useState(false);
  
  
    const [porcentagePie, setPorcentagePie] = useState([]);
  
  
    const [periodos, setPeriodos] = useState([]);
  
    const [listadoPeriodos, setListadoPeriodos] = useState([]);
  
    const [desagregacion, setDesagregacion] = useState(false);
    const [relacionados, setRelacionados] = useState(false);
  
    const [mostrarGrafico, setMostrarGrafico] = useState({});
    let mulObj = useRef(null);
    let graficoLinea = false;
    let graficoBarra = false;
    let graficoPie = false;
    let graficoTabla = false;
  
  function cleanChart() {
    setChartBar(false);
    setChartPie(false);
    setCharLine(false);
  }

  const generateValoresIndicadores = (valoresIndicadores) => {
    if (Object.keys(valoresIndicadores).length > 0) {
      let arraybar = [];
      let header = {
        title: `${valoresIndicadores.nombre}`,
        unidadMedida: `Datos en (${valoresIndicadores.moneda})`,
        entidad:
          entidadSelected && entidadSelected > 0
            ? indicadores[1].data.find((x) => x.id == entidadSelected).nombre
            : "",
      };
      setHeaderChart(header);
      if (chartSelected !== "chart-line") {
        let arrayValores = [];
        valoresIndicadores.valor.forEach((indicador) => {
          arraybar = [
            ...arraybar,
            {
              name: indicador.ano,
              x: `${moment(
                `${indicador.ano}/${indicador.mes}/12`,
                "yyyy/MM/DD"
              ).format("MMM-YY")}`,
              y: indicador.valor,
              placement: `${indicador.valor}`,
            },
          ];
          arrayValores = [...arrayValores, indicador.valor];
        });
        setMaxValueY(Math.max(...arrayValores));
        setMinValueY(Math.min(...arrayValores));
        setValoresIndicadores(arraybar);
      } else {
        let arrayValores = [];
        valoresIndicadores.valor.forEach((indicador) => {
          arrayValores = [...arrayValores, indicador.valor];
        });

        let valoresIndicadoresCalculados = [];

        valoresIndicadores.valor.forEach((valorIndicador) => {
          valoresIndicadoresCalculados = [
            ...valoresIndicadoresCalculados,
            {
              indicadorCalculadoId: valorIndicador.id,
              valor: valorIndicador.valor,
              ano: valorIndicador.ano,
              mes: valorIndicador.mes,
            },
          ];
        });

        let valoresIndicadoresChart = [
          {
            indicador: valoresIndicadores.nombre,
            valoresIndicadoresCalculados,
          },
        ];

        setMaxValueY(Math.max(...arrayValores));
        setMinValueY(Math.min(...arrayValores));
        setValoresIndicadores(valoresIndicadoresChart);
      }
    }
    setInitialLoading(false);
  };

  const generateValoresDesagregados = (valoresDesagregados) => {
    if (valoresDesagregados?.length > 0) {
      let indicadorSearch = indicadoresList.find(
        (x) => x.id == indicadorSelected
      );

      let header = {
        title: `${indicadorSearch?.nombre.replace(/-/gi, "")}`,
        unidadMedida: `Datos en ${indicadorSearch?.moneda}`,
        entidad:
          entidadSelected && entidadSelected > 0
            ? indicadores[1].data.find((x) => x.id == entidadSelected).nombre
            : "",
      };

      setHeaderChart(header);
      let arrayPie = [];
      let sum = 0;
      valoresDesagregados.map((valor) => {
        sum += parseFloat(valor.valor[0].valor);
      });
      console.log("sum", sum);
      setPorcentagePie(sum);
      valoresDesagregados.map((data, key) => {
        if (data) {
          arrayPie = [
            ...arrayPie,
            {
              name: `${data.nombre} (${Math.round(
                (parseFloat(data.valor[0].valor) / sum) * 100
              )}%)`,
              x: parseFloat(data.valor[0].valor),
              y: parseFloat(data.valor[0].valor),
              placement: `${data.valor[0].valor}% `,
            },
          ];
        }
        return true;
      });

      setValoresIndicadoresDesagregados(arrayPie);
    }

    setInitialLoading(false);
  };

  useEffect(() => {
    if (valoresIndicadoresDesagregados?.length > 0) {
      validateChart();
      setInitialLoading(false);
    }
  }, [valoresIndicadoresDesagregados]);

  useEffect(() => {
    if (valoresIndicadores.length > 0) {
      validateChart();
      setInitialLoading(false);
    }
  }, [valoresIndicadores]);

  function genChart() {
    cleanChart();
    setInitialLoading(true);

    let indicadorSearch = indicadoresList.find(
      (x) => x.id == indicadorSelected
    );
    if (indicadorSelected && indicadorSelected > 0) {
      if (listadoPeriodos.length === 0) {
        setInitialLoading(false);
        alert("Debe de elegir al menos un período");
      } else {
        setInitialLoading(true);
        let periodosIds = [];
        listadoPeriodos.map((periodo, key) => {
          periodosIds += `&periodoId[${key}]=${periodo}`;
        });
        if (indicadorSearch.desagregacion && listadoPeriodos.length == 1) {
          periodosIds = `&periodoId=${listadoPeriodos[0]}`;

          getDataCatalogos([
            `${endpoints.financiero_desagregados}?entidadId=${entidadSelected}&indicadorId=${indicadorSelected}${periodosIds}`,
          ]).then((data) => {
         
            generateValoresDesagregados(data[0].data);
          });
       
        } else {
          getDataCatalogos([
            `${endpoints.financiero_valores_indicadores}?entidadId=${entidadSelected}&indicadorId=${indicadorSelected}${periodosIds}`,
          ]).then((data) => {
            console.log("valores indicadores", data[0].data);
            generateValoresIndicadores(data[0].data);
          });
          
        }
      }
    }
    setInitialLoading(false);
  }

  function validateChart() {
    switch (chartSelected) {
      case "chart-line":
        setChartBar(false);
        setChartPie(false);
        setCharLine(true);
        break;
      case "chart-bar":
        setCharLine(false);
        setChartPie(false);
        setChartBar(true);
        break;
      case "chart-pie":
        setChartBar(false);
        setCharLine(false);
        setChartPie(true);
        break;
    }
  }

  // map the groupBy field with category column
  let fields = { groupBy: "ano", text: "mesNombre", value: "id" };

  const handleSelect = (e) => {
    const { name, value } = e.target;
    if (name === "entidad") {
      setEntidadSelected(value);
    }
    if (name === "indicador") {
      setIndicadorSelected(value);
    }
  };

  const onSelect = (args) => {
    let p = [];

    p = args.value;

    setListadoPeriodos(p);
  };

  useEffect(() => {
    if (!initialLoading) {
      getDataCatalogos([
        endpoints.financiero_periodos,
        endpoints.financiero_entidades,
      ]).then((data) => {
        setInitialLoading(true);
        setIndicadores(data);
      });
    }
  }, []);

  useEffect(() => {
    if (entidadSelected > 0) {
      setInitialLoading(true);
      getDataCatalogos([
        `${endpoints.financiero_indicadores}?entidadId=${entidadSelected}`,
      ]).then((data) => {
        setInitialLoading(true);
        setIndicadoresList(data[0].data);
        setTimeout(() => {
          setInitialLoading(false);
        }, 1000);
      });
    } else {
      setIndicadoresList([]);
    }
  }, [entidadSelected]);

  useEffect(() => {
   
    let indicadoresPeriodos = [];

    if (indicadores[0] && indicadores[0] !== []) {
      let periodoOrdenado = sort(indicadores[0].data).desc([(u) => u.ano]);
      periodoOrdenado.map((i) => {
        indicadoresPeriodos = [
          ...indicadoresPeriodos,
          {
            id: i.id,
            ano: i.ano,
            mes: i.mes,
            mesNombre: meses.find((x) => x.id == i.mes)?.nombre, //moment(`2021-${i.mes}-12`).format("MMMM"),
            estado: i.estado,
          },
        ];

        moment.locale("es");

        setPeriodos(indicadoresPeriodos);
      });
    }
    setInitialLoading(false);
  }, [indicadores]);

  useEffect(() => {
    if (listadoPeriodos.length === 1) {
      setDesagregacion(true);
      setRelacionados(false);
    } else if (listadoPeriodos.length > 1) {
      setRelacionados(true);
      setDesagregacion(false);
    }
  }, [listadoPeriodos]);

  useEffect(() => {
    let tipoGrafico = [];
    let indicadorSearch = indicadoresList.find(
      (x) => x.id == indicadorSelected
    );

    if (
      indicadorSelected &&
      indicadorSelected > 0 &&
      //   periodosSelected.length > 1

      listadoPeriodos.length > 1
    ) {
      tipoGrafico = tipoGraficoIndicadores.find(
        (x) => x.indicador == indicadorSelected
      );
      setMostrarGrafico(tipoGrafico);
    } else if (
      //   periodosSelected.length == 1 &&
      listadoPeriodos.length == 1 &&
      indicadorSelected &&
      indicadorSelected > 0 &&
      indicadorSearch.desagregacion
    ) {
      tipoGrafico = tipoGraficoIndicadores.find((x) => x.indicador == 45);
      setMostrarGrafico(tipoGrafico);
    } else {
      setMostrarGrafico({});
    }
  }, [indicadorSelected, listadoPeriodos]);

  return (
    <div className="col-12 col-md-8 col-lg-9">
      <article className="content-servicios main-content">
       
        <div
          className="container-fluid"
          style={{ backgroundColor: colors.azulFondoGraficos }}
        >
          <div className="row">
          
            <div className="col-sm-12 col-lg-4 text-center bg-charts">
              <div className="form-group">
                <h5>Empresa</h5>
                <select
                  className="custom-select form-control"
                  name="entidad"
                  onChange={handleSelect}
                >
                  <option value="0">Seleccionar Empresa</option>
                  {indicadores[1] &&
                    indicadores[1].data.map((e) => {
                      return <option value={e.id}>{e.nombre}</option>;
                    })}
                </select>
              </div>
            </div>

            <div className="col-lg-4 text-center bg-charts">
              <div className="form-group">
                <h5>Indicador</h5>

                <select
                  className="custom-select form-control"
                  name="indicador"
                  onChange={handleSelect}
                >
                  <option value="0">Seleccionar Indicador</option>
                  {indicadoresList.map((e) => {
                    return <option value={e.id}>{e.nombre}</option>;
                  })}
                </select>
              </div>
            </div>

            <div className="col-lg-4 text-center bg-charts">
              <div className="form-group">
                <h5>Periodo</h5>
                <MultiSelectComponent
                  id="mtselement"
                  popupHeight="400px"
                  fields={fields}
                  dataSource={periodos}
                  placeholder="Selecciona un período"
                  mode="CheckBox"
                  enableGroupCheckBox="true"
                  allowFiltering="true"
                  showSelectAll="true"
                  filterBarPlaceholder="Buscar períodos"
                  ref={(scope) => {
                    mulObj = scope;
                  }}
                  change={onSelect.bind(this)}
                >
                  <Inject services={[CheckBoxSelection]} />
                </MultiSelectComponent>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-12 bg-charts text-center">
              <h5>Ver como</h5>
              {Object.keys(mostrarGrafico).length > 0 &&
                mostrarGrafico.tipo_grafico.map((item) => {
                  switch (item) {
                    case "chart-line":
                      graficoLinea = true;
                      break;
                    case "chart-bar":
                      graficoBarra = true;

                      break;
                    case "chart-pie":
                      graficoPie = true;
                      break;
                    case "table":
                      graficoTabla = true;
                      break;

                    default:
                      graficoLinea = false;
                      graficoBarra = false;
                      graficoTabla = false;
                  }
                })}

              <div className="d-flex flex-row mb-3 mt-3 justify-content-center align-items-center">
                {graficoPie ||
                seleccion.indicador_descendiente !== undefined ? (
                  <div
                    className={
                      seleccion.indicador_descendiente == 0
                        ? " d-none"
                        : chartSelected === "chart-pie"
                        ? "p-2 border border-primary bg-info"
                        : "p-2"
                    }
                  >
                    <a
                      data-toggle="tooltip"
                      data-placement="top"
                      title="Gráfico de pastel"
                      onClick={() => setChartSelected("chart-pie")}
                    >
                      <img
                        className={
                          chartSelected === "chart-pie"
                            ? "img-fluid hover-filter-chart"
                            : "img-fluid"
                        }
                        src={
                          process.env.PUBLIC_URL +
                          "/img/graficos/tipo/pie-chart.png"
                        }
                      />
                    </a>
                  </div>
                ) : null}
                {graficoBarra ? (
                  <div
                    className={
                      chartSelected === "chart-bar"
                        ? "p-2 border border-primary bg-info"
                        : "p-2"
                    }
                  >
                    <a
                      data-toggle="tooltip"
                      data-placement="bottom"
                      title="Gráfico de barra"
                      onClick={() => setChartSelected("chart-bar")}
                    >
                      <img
                        className={
                          chartSelected === "chart-bar"
                            ? "img-fluid hover-filter-chart"
                            : "img-fluid"
                        }
                        src={
                          process.env.PUBLIC_URL +
                          "/img/graficos/tipo/chart-of-columns.png"
                        }
                      />
                    </a>
                  </div>
                ) : null}

                {graficoLinea && !seleccion.indicador_descendiente ? (
                  <div
                    className={
                      chartSelected === "chart-line"
                        ? "p-2 border border-primary bg-info"
                        : "p-2"
                    }
                  >
                    <a
                      data-toggle="tooltip"
                      data-placement="left"
                      title="Gráfico de linea"
                      onClick={() => setChartSelected("chart-line")}
                    >
                      <img
                        className={
                          chartSelected === "chart-line"
                            ? "img-fluid hover-filter-chart"
                            : "img-fluid"
                        }
                        src={
                          process.env.PUBLIC_URL +
                          "/img/graficos/tipo/arrow-chart.png"
                        }
                        alt="gráfico de línea"
                      />
                    </a>
                  </div>
                ) : null}

                {graficoTabla && seleccion.indicador_descendiente ? (
                  <div
                    className={
                      chartSelected === "table"
                        ? "p-2 border border-primary p-2 ml-2 border-left border-dark bg-info"
                        : "p-2"
                    }
                  >
                    <a
                      data-toggle="tooltip"
                      data-placement="right"
                      title="Tabla"
                      onClick={() => setChartSelected("table")}
                    >
                      <img
                        className={
                          chartSelected === "table"
                            ? "img-fluid hover-filter-chart"
                            : "img-fluid"
                        }
                        src={
                          process.env.PUBLIC_URL +
                          "/img/graficos/tipo/table.png"
                        }
                        alt="gráfico de tabla"
                      />
                    </a>
                  </div>
                ) : null}
                {initialLoading ? (
                  <Loading></Loading>
                ) : (
                  <div className="p-2 ml-2 border-left border-info">
                    <button
                      onClick={() => genChart()}
                      className="ml-2 btn btn-secondary-mem rounded-0 font-weight-bold"
                    >
                      Filtrar
                      <i className="ml-2 fas fa-search fa-lg"></i>
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>

          {chartLine && (
            <LineChart
              dataLineChart={valoresIndicadores}
              maxValueY={maxValueY}
              minValueY={minValueY}
              headerChart={headerChart}
            />
          )}

          {chartBar && (
            <BarChart
              maxValueY={maxValueY}
              minValueY={minValueY}
              headerChart={headerChart}
              arrayData={valoresIndicadores}
            />
          )}
          {chartPie && (
            <PieChart
              headerChart={headerChart}
              dataPieChart={valoresIndicadoresDesagregados}
              porcentagePie={porcentagePie}
            />
          )}

          {/* end row */}
        </div>
      </article>
    </div>
  );
};

