import { VictoryPie, VictoryLegend } from 'victory';
import axios from 'axios';
import { useState, useEffect, React } from 'react';
import moment from "moment";
import { numberFormat } from "../../../utils/separador-miles"
import { colors } from "../../../theme/theme";

import { endpoints } from "../../../config"
import { Loading } from '../../Loading';

import { CustomLabel } from './../CustomLabel';

export const GeneracionTipoTecnologia = () => {
  const generacionTipoTecnologia = endpoints.generacionTipoTecnologia;
  const [generacionTipoEnergia, setGeneracionTipoEnergia] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  let generacionTipoTecnologiaList = [];

  const getGeneracionTipoEnergia = async (fecha) => {
    await axios.get(`${generacionTipoTecnologia}?fecha=${fecha}`, { headers: { "Access-Control-Allow-Origin": "*", 'Content-Type': "application/json" } })
      .then(rep => {
        Object.entries(rep.data["data"]).map(item => {
          if (item[1].promedio > 0) {
            generacionTipoTecnologiaList = [...generacionTipoTecnologiaList, {
              name: `${item[1].tecnologia}: ${numberFormat(item[1].promedio)}%`,
              x: item[1].tecnologia,
              y: item[1].promedio,
              placement: `${item[1].tecnologia}: ${numberFormat(item[1].promedio)}% `
            }]
          }

          return true;
        })

        setGeneracionTipoEnergia(generacionTipoTecnologiaList);

      }).catch(err => {
        console.log(err);
        document.getElementById("generacionTipoEnergia").innerHTML = "<h4 class='text-center'>Ha ocurrido un error al tratar de cargar los datos.</h4>";
      })
  }

  useEffect(() => {
    let fecha = moment(new Date()).format("yyyy-MM-DD");
    getGeneracionTipoEnergia(fecha);
    setTimeout(() => {
      setIsLoading(false);
    }, 500);
  }, []);

  return (
    <div className="d-flex flex-column bg-white">

      {isLoading ?
        <div className="text-center">
          <Loading />
        </div>
        :
        <div>
          <div className="px-1" >
            <h3 className="mb-3 title-chart">Abastecimiento por Tipo de Tecnología</h3>
            <VictoryPie
              colorScale={[colors.azulOscuro, colors.verde, colors.rojo, colors.verdeOscuro, colors.marron, "tomato", "cyan", colors.azulTabla, colors.azulClaro]}
              data={generacionTipoEnergia.length > 0 ? generacionTipoEnergia : []}
              labels={({ datum }) =>  `${numberFormat(datum.y)}%` }
              innerRadius={100}
              padding={{ bottom: 30 }}
              width={800}
              height={350}
              style={{ labels: { fontSize: 11, fontWeight: 'bold' } }}
              labelComponent={<CustomLabel data={generacionTipoEnergia.length > 0 ? generacionTipoEnergia : []}  angle={45}/>}
            />
          </div>

          <div className="px-1">

            {
              isLoading ?
                <Loading />
              :
              <div id="generacionTipoEnergia">
                <VictoryLegend
                  colorScale={[colors.azulOscuro, colors.verde, colors.rojo, colors.azulClaro, colors.marron, "tomato", "cyan", colors.azulTabla, colors.azulClaro]}
                  centerTitle
                  itemsPerRow={3}
                  orientation="vertical"
                  gutter={50}
                  style={{ labels: { fontSize: 9, fontWeight: 'bold' } }}
                  data={generacionTipoEnergia}
                  height={100}
                  width={700}
                  x={80}
                  y={30}
                />
              </div>
              
            }
            
          </div>

        </div>

      }

    </div>

  )
}