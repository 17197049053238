// export const apiBase = "https://localhost:5001/api";
export const apiBase = "https://memapiv2.mem.gob.do/api";

export const endpoints = {
  login: `${apiBase}/Auth/login`,
  refreshtoken: `${apiBase}/auth/refresh`,
  usuarios: `${apiBase}/Auth/lista`,
  resumenDiario: `${apiBase}/despachodiario/resumendiario`,
  produccionEnergeticaFp: `${apiBase}/despachodiario/produccionenergeticafp`,
  disponibilidadGeneracionDiaria: `${apiBase}/despachodiario/disponibilidadgeneraciondiaria`,
  generacionDeclaradaVsProgramada: `${apiBase}/despachodiario/generaciondeclaradavsprogramada`,
  generacionTipoTecnologia: `${apiBase}/despachodiario/generaciontipotecnologia`,
  noticias: "https://mem.gob.do/wp-json/wp/v2/posts",

  comercial_indicadores: `/datoscomerciales/listaindicadores`,
  comercial_periodos: `/datoscomerciales/listaperiodos`,
  comercial_entidades: `/datoscomerciales/listaentidades`,
  comercial_valores: `/datoscomerciales/valoresindicadorescalculados`,

  //Este es para las desagregaciones
  comercial_indicador_calculado_descendencias: `/datoscomerciales/indicadorcalculadodescendecias`,

  //Este es para los indicadores que pueden ser comparables con otros
  comercial_valores_calculados_relacionados: `datoscomerciales/ValoresIndicadoresCalculadosrelacionados`,

  financiero_indicadores: `${apiBase}/datosFinancieros/Financiero/listaIndicadores`,
  financiero_entidades: `${apiBase}/datosFinancieros/Financiero/listaEntidades`,
  financiero_periodos: `${apiBase}/datosFinancieros/Financiero/listaPeriodos`,
  financiero_valores_indicadores: `${apiBase}/datosFinancieros/Financiero/valoresIndicador`,
  financiero_desagregados: `${apiBase}/datosFinancieros/Financiero/valoresDesagregados`,
};

export const meses = [
  { id: 1, nombre: "Enero" },
  { id: 2, nombre: "Ferebro" },
  { id: 3, nombre: "Marzo" },
  { id: 4, nombre: "Abril" },
  { id: 5, nombre: "Mayo" },
  { id: 6, nombre: "Junio" },
  { id: 7, nombre: "Julio" },
  { id: 8, nombre: "Agosto" },
  { id: 9, nombre: "Septiembre" },
  { id: 10, nombre: "Octubre" },
  { id: 11, nombre: "Noviembre" },
  { id: 12, nombre: "Diciembre" },
];

export const tipoGraficoIndicadores = [
  {
    indicador: 1,
    tipo_grafico: ["chart-line", "chart-bar"],
  },
  {
    indicador: 2,
    tipo_grafico: ["chart-line", "chart-bar"],
  },
  {
    indicador: 3,
    tipo_grafico: ["chart-line"],
  },
  {
    indicador: 4,
    tipo_grafico: ["chart-line"],
  },
  {
    indicador: 5,
    tipo_grafico: ["chart-line"],
  },
  {
    indicador: 6,
    tipo_grafico: ["chart-line"],
  },
  {
    indicador: 7,
    tipo_grafico: ["chart-line", "chart-bar"],
  },
  {
    indicador: 8,
    tipo_grafico: ["chart-line", "chart-bar"],
  },
  {
    indicador: 9,
    tipo_grafico: ["chart-line", "chart-bar"],
  },
  {
    indicador: 10,
    tipo_grafico: ["chart-line", "chart-bar"],
  },
  {
    indicador: 11,
    tipo_grafico: ["chart-bar"],
  },
  {
    indicador: 12,
    tipo_grafico: ["chart-line", "chart-bar"],
  },
  {
    indicador: 13,
    tipo_grafico: ["chart-line", "chart-bar"],
  },
  {
    indicador: 14,
    tipo_grafico: ["chart-line", "chart-bar"],
  },
  {
    indicador: 15,
    tipo_grafico: ["chart-line", "chart-bar"],
  },
  {
    indicador: 16,
    tipo_grafico: ["chart-line"],
  },
  {
    indicador: 17,
    tipo_grafico: ["chart-line"],
  },
  {
    indicador: 18,
    tipo_grafico: ["chart-line"],
  },
  {
    indicador: 19,
    tipo_grafico: ["chart-line"],
  },
  {
    indicador: 20,
    tipo_grafico: ["chart-bar"],
  },
  {
    indicador: 21,
    tipo_grafico: ["chart-bar"],
  },
  {
    indicador: 22,
    tipo_grafico: ["chart-bar"],
  },
  {
    indicador: 23,
    tipo_grafico: ["chart-bar"],
  },
  {
    indicador: 24,
    tipo_grafico: ["chart-bar"],
  },
  {
    indicador: 25,
    tipo_grafico: ["chart-bar"],
  },
  {
    indicador: 26,
    tipo_grafico: ["chart-line"],
  },
  {
    indicador: 27,
    tipo_grafico: ["chart-line"],
  },
  {
    indicador: 28,
    tipo_grafico: ["chart-line"],
  },
  {
    indicador: 29,
    tipo_grafico: ["chart-bar"],
  },
  {
    indicador: 30,
    tipo_grafico: ["chart-bar"],
  },
  {
    indicador: 31,
    tipo_grafico: ["chart-line"],
  },
  {
    indicador: 32,
    tipo_grafico: ["chart-bar"],
  },
  {
    indicador: 33,
    tipo_grafico: ["chart-bar"],
  },
  {
    indicador: 34,
    tipo_grafico: ["chart-bar"],
  },
  {
    indicador: 35,
    tipo_grafico: ["chart-line", "chart-bar"],
  },
  {
    indicador: 36,
    tipo_grafico: ["chart-line", "chart-bar"],
  },
  {
    indicador: 37,
    tipo_grafico: ["chart-line", "chart-bar"],
  },
  {
    indicador: 38,
    tipo_grafico: ["chart-bar"],
  },
  {
    indicador: 39,
    tipo_grafico: ["chart-bar"],
  },
  {
    indicador: 40,
    tipo_grafico: ["chart-bar"],
  },
  {
    indicador: 41,
    tipo_grafico: ["chart-bar"],
  },
  {
    indicador: 42,
    tipo_grafico: ["chart-bar"],
  },
  {
    indicador: 43,
    tipo_grafico: ["chart-line"],
  },
  {
    indicador: 44,
    tipo_grafico: ["chart-line"],
  },
  {
    indicador: 45,
    tipo_grafico: ["chart-pie"],
  },
];
