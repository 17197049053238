import { Link } from 'react-router-dom';

export const Footer = () => {
    return (
        <div className="c-footer container" style={{  clear: 'both' }}>
            <div className="row">
                <footer className="mt-2 ">
                    <div className="row p-4">
                        {/* <div className="d-flex flex-row ml-3">
                            <div className="p-2">
                                <Link className="btn btn-light rounded-0 font-weight-bold" to="/pag/contacto">SOLICITAR MÁS INFORMACIÓN</Link>
                            </div>
                            <div className="p-2">
                                <div className="d-flex justify-content-between">
                                    <div className="p-1">
                                        <i className="fab fa-facebook text-white fa-lg"></i>
                                    </div>
                                    <div className="p-1">
                                        <i className="fab fa-instagram text-white fa-lg"></i>
                                    </div>
                                    <div className="p-1">
                                        <i className="fab fa-youtube text-white fa-lg"></i>
                                    </div>
                                    <div className="p-1">
                                        <i className="fab fa-linkedin text-white fa-lg"></i>
                                    </div>
                                    <div className="p-1">
                                        <i className="fab fa-twitter text-white fa-lg"></i>
                                    </div>
                                </div>
                            </div>
                            <div className="p-2 text-white">
                                <p className="font-weight-bold">energiayminas</p>
                            </div>
                        </div> */}
                    </div>
                </footer>
            </div>

            {/* <button href="#" id="back-to-top" className="rounded back-to-top btn btn-secondary-mem" role="link" title="Regresar arriba" data-toggle="tooltip" data-placement="left" style={{ display: 'inline' }}><i className="fas fa-angle-up"></i></button> */}
        </div>
    )
}