import React ,{ useState, useEffect } from "react";

import {VictoryChart,VictoryLegend,VictoryLine,VictoryLabel} from 'victory';
import moment from "moment";
import { colors } from "../../../theme/theme";
import axios from 'axios';
import { endpoints } from "../../../config";
import { Loading } from "../../Loading";

export const DisponibilidadGeneracionDiaria = () => {

    const [isLoading, setIsLoading] = useState(true);
    const [disponibilidadRealSincronizada, setDisponibilidadRealSincronizada] = useState([]);
    const [potenciaInstantaneaGenerada, setPotenciaInstantaneaGenerada] = useState([]);
    const [potenciaProgramada, setPotenciaProgramada] = useState([]);
    // const [reservaCaliente, setReservaCaliente] = useState([]);
    // const [periodoMayor,setPeriodoMayor] = useState(10);
    const disponibilidadGeneracionDiariaURL = endpoints.disponibilidadGeneracionDiaria;

    useEffect(()=>{
        let fecha = moment(new Date()).format("yyyy-MM-DD");
        generarDisponibilidadGeneracionDiaria(fecha);
        setTimeout(() => {
            setIsLoading(false);
        }, 500);
    },[]);

    const generarDisponibilidadGeneracionDiaria  = async(fecha) => {
       
        let disponibilidadRealSincronizadaList = [];
        let potenciaInstantaneaGeneradaList = [];
        let potenciaProgramadaList = [];
        let reservaCalienteList = [];
        let periodoList = [];

        await axios.get(`${disponibilidadGeneracionDiariaURL}?fecha=${fecha}`, { headers: { "Access-Control-Allow-Origin": "*", 'Content-Type': "application/json" } })
        
        .then(rep => {
            Object.entries(rep.data["data"]).map(disponibilidadGeneracion => {

                disponibilidadRealSincronizadaList = [...disponibilidadRealSincronizadaList, {
                    name:`${disponibilidadGeneracion[1].periodo}`,
                    x: disponibilidadGeneracion[1].periodo,
                    y: disponibilidadGeneracion[1].disponibilidadRealSincronizada,
                    placement: `${disponibilidadGeneracion[1].disponibilidadRealSincronizada}`
                } ]
                potenciaInstantaneaGeneradaList = [...potenciaInstantaneaGeneradaList, {
                    name:`${disponibilidadGeneracion[1].periodo}`,
                    x: disponibilidadGeneracion[1].periodo,
                    y: disponibilidadGeneracion[1].potenciaInstantaneaGenerada,
                    placement: `${disponibilidadGeneracion[1].potenciaInstantaneaGenerada}`
                } ]
                potenciaProgramadaList = [...potenciaProgramadaList, {
                    name:`${disponibilidadGeneracion[1].periodo}`,
                    x: disponibilidadGeneracion[1].periodo,
                    y: disponibilidadGeneracion[1].potenciaProgramada,
                    placement: `${disponibilidadGeneracion[1].potenciaProgramada}`
                } ]
                reservaCalienteList = [...reservaCalienteList, {
                    name:`${disponibilidadGeneracion[1].periodo}`,
                    x: disponibilidadGeneracion[1].periodo,
                    y: disponibilidadGeneracion[1].reservaCaliente,
                    placement: `${disponibilidadGeneracion[1].reservaCaliente}`
                } ]
                periodoList = [...periodoList,disponibilidadGeneracion[1].periodo ]


                // setPeriodoMayor(Math.max(...periodoList)) ;
                setDisponibilidadRealSincronizada(disponibilidadRealSincronizadaList);  
                setPotenciaInstantaneaGenerada(potenciaInstantaneaGeneradaList);  
                setPotenciaProgramada(potenciaProgramadaList);  
                // setReservaCaliente(reservaCalienteList);

                return true;

            });
        }).catch(err => {
            console.log(err);
        });


    }

    return (

        <div className="d-flex flex-column ">
            <div className="p-1 mt-2 gen-diaria">
                <h3 className="mb-3 title-chart">Abastecimiento vs Disponibilidad del día </h3>
                {
                    isLoading ?
                    <Loading />
                    :
                    <VictoryChart  domainPadding={35}  minDomain={{ y: 1800 }} >
                        <VictoryLine  style={{data:{stroke:colors.verdeOscuro},labels:{fill:colors.verdeOscuro, fontSize:10 }}} name="Disponibilidad Real Sincronizada" labelComponent={<VictoryLabel renderInPortal  /> }    labels={({datum})=>  (datum.x % 2 === 0) ?  `${datum.y}` :  '' }    data={disponibilidadRealSincronizada}/>
                        <VictoryLine  style={{data:{stroke:colors.azulClaro},labels:{fill:colors.azulClaro , fontSize:10}}}   name="Total Programado"  labelComponent={<VictoryLabel renderInPortal dy={20} />} labels={({datum})=> (datum.x % 2 === 0) ?  `${datum.y}` :  ''} data={potenciaProgramada}/>
                        <VictoryLine  style={{data:{stroke:colors.rojo},labels:{fill:colors.rojo , fontSize:10}}}  name="Demanda Real" labelComponent={<VictoryLabel renderInPortal dy={-10} />}  labels={({datum})=> (datum.x % 2 === 0) ?  `${datum.y}` :  ''} data={potenciaInstantaneaGenerada}/>
                       
                        {/* <VictoryLine data={reservaCaliente}/> */}
                            <VictoryLegend 
                                height={150}
                                width={800}
                                centerTitle
                                itemsPerRow={3}
                                orientation="horizontal"
                                gutter={20}
                                x={40}
                                y={10}
                                style={{ border: { width:"100%",padding:0,justifyContent:"center"},labels:{fontSize:11}, title: {fontSize: 11 },parent:{margin:0}}}  
                                data={[
                                    { name: "Demanda real", symbol: { fill: colors.rojo, type: "minus" } },
                                    { name: "Total programado", symbol: { fill: colors.azulClaro,type:"minus" } },
                                    { name: "Disponibilidad Real Sinc", symbol: { fill: colors.verdeOscuro,type:"minus" } }
                                ]}
                            />
                    </VictoryChart>
                }
                
            </div>
        </div>
    )
}