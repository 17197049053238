import axios from 'axios';
import { apiBase } from "../config";
//import SyncStorage from '../utils/SyncStorage';

const qs = require('querystring')

const instance = axios.create({
    baseURL: apiBase,
    timeout: 20000,
//    maxContentLength: 1000000,
/*    data: {
        client_id: client.id,
        client_secret: client.secret
    },*/
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Accept': 'application/json'
    },
    transformRequest: [function (data, headers) {
        //console.log(qs.stringify(data));
        return  headers['Content-Type'] === "multipart/form-data" ? data : qs.stringify(data);
    }],
});

export default instance;