import routesNavbar from "../routes/routes-main-menu";
import { NavLink } from 'react-router-dom';
import dse from './../assets/img/dse.svg';
import rayo from './../assets/img/rayo.svg';

export const Header = () => {

    const busqueda = () => {

    }

    return(
        <header id="main">
            <div className="container">
                <div className="row">
                    <div className="col-12 col-md-4 order-md-2 _d-flex _justify-content-between _justify-content-md-end">
                        <div className="row">
                            <div className="col-12 d-flex mb-2 justify-content-center justify-content-md-end pr-0">
                                <img width="380px" src={dse} alt="Datos sector energia"/>
                            </div>
                            <div className="col-12 d-none d-md-flex justify-content-end">
                                
                                {/* <div className="d-flex flex-column">
                                    <div>
                                        <form role="search" method="get" id="searchform" className="searchform"
                                            action="#">
                                            <input className="form-control border-0 rounded-0" type="search" value="" name="s" id="s" aria-label="Búsqueda" onChange={busqueda} />
                                            <button className="" >
                                                <em className="fas fa-search"></em>
                                            </button>
                                        </form>
                                    </div>

                                    <div className="header-nav" >
                                        <a href="/">Inicio</a>

                                        {routesNavbar.map((prop, key) => {
                                            if (prop.menu_header) {
                                                return (
                                                    <span key={key}>
                                                        |
                                                        <NavLink
                                                            key={key}
                                                            to={ prop.layout + prop.path}
                                                            activeClassName="active"
                                                        >
                                                            {prop.name}
                                                            
                                                        </NavLink>
                                                        
                                                    </span>
                                                        
                                                );
                                                
                                            }
                                            return null;
                                        })}	
                                    </div>
                                </div> */}
                            </div>

                        </div>
                    </div>
                    <div className="col-12 d-flex d-md-none justify-content-end">
                        <hr className="w-100" />
                    </div>
                    <div className="col-12 col-md-2 order-md-1 d-flex justify-content-center justify-content-md-start pt-4 pl-4">
                        <a href="/" className="logo mb-3" aria-label="Logo">
                            <span className="sr-only">Logo Ministerio de Energía y Minas</span>
                        </a>
                    </div>

                    <div className="col-12 col-md-6 order-md-1 d-flex justify-content-start">
                        <img className="rayo" src={rayo} height="135px" alt="rayo" />
                    </div>
                </div>
            </div>
        </header>
        
    )
}