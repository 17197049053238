import React, { useState, useEffect } from "react";

import moment from "moment";
import { numberFormat } from "../../../utils/separador-miles";
import axios from 'axios';
import { endpoints } from "../../../config";
import { Loading } from "../../Loading";
import { colors } from "../../../theme/theme";

export const ResumenDiario = (props) => {

    const [isLoading, setIsLoading] = useState(true);
    const [ dateSearch, setDateSearch ]  = useState("");
    const [totalGenerado, setTotalGenerado] = useState({});
    const [demandaReal, setDemandaReal] = useState({});
    const [potenciaNoServida, setPotenciaNoServida] = useState({});
    const [totalProgramado, setTotalProgramado] = useState({});
    const [totalTermico, setTotalTermico] = useState({});
    const [totalEolico, setTotalEolico] = useState({});
    const [totalSolar, setTotalSolar] = useState({});
    const [totalHidroelectrica, setTotalHidroelectrica] = useState({});
    const [totalPorcentajeConsumido, setTotalPorcentajeConsumido] = useState([]);

    const [menuHoras, setMenuHoras] = useState([
        { id: 1, name: "1:00 A.M", property: "unaAm", enabled: false, disabled: false, desc: '01' },
        { id: 2, name: "2:00 A.M", property: "dosAm", enabled: false, disabled: false, desc: '02' },
        { id: 3, name: "3:00 A.M", property: "tresAm", enabled: false, disabled: false, desc: '03' },
        {
          id: 4,
          name: "4:00 A.M",
          property: "cuatroAm",
          enabled: false,
          disabled: false,
          desc: '04'
        },
        {
          id: 5,
          name: "5:00 A.M",
          property: "cincoAm",
          enabled: false,
          disabled: false,
          desc: '05'
        },
        { id: 6, name: "6:00 A.M", property: "seisAm", enabled: false, disabled: false, desc: '06' },
        {
          id: 7,
          name: "7:00 A.M",
          property: "sieteAm",
          enabled: false,
          disabled: false,
          desc: '07'
        },
        { id: 8, name: "8:00 A.M", property: "ochoAm", enabled: false, disabled: false, desc: '08' },
        {
          id: 9,
          name: "9:00 A.M",
          property: "nueveAm",
          enabled: false,
          disabled: false,
          desc: '09'
        },
        {
          id: 10,
          name: "10:00 A.M",
          enabled: false,
          property: "diezAm",
          disabled: false,
          desc: '10'
        },
        {
          id: 11,
          name: "11:00 A.M",
          enabled: false,
          property: "onceAm",
          disabled: false,
          desc: '11'
        },
        {
          id: 12,
          name: "12:00 P.M",
          enabled: false,
          property: "docePm",
          disabled: false,
          desc: '12'
        },
        { id: 13, name: "1:00 P.M", enabled: false, property: "unaPm", disabled: false, desc: '13' },
        { id: 14, name: "2:00 P.M", enabled: false, property: "dosPm", disabled: false, desc: '14'},
        {
          id: 15,
          name: "3:00 P.M",
          enabled: false,
          property: "tresPm",
          disabled: false,
          desc: '15'
        },
        {
          id: 16,
          name: "4:00 P.M",
          enabled: false,
          property: "cuatroPm",
          disabled: false,
          desc: '16'
        },
        {
          id: 17,
          name: "5:00 P.M",
          enabled: false,
          property: "cincoPm",
          disabled: false,
          desc: '17'
        },
        {
          id: 18,
          name: "6:00 P.M",
          enabled: false,
          property: "seisPm",
          disabled: false,
          desc: '18'
        },
        {
          id: 19,
          name: "7:00 P.M",
          enabled: false,
          property: "sietePm",
          disabled: false,
          desc: '19'
        },
        {
          id: 20,
          name: "8:00 P.M",
          enabled: false,
          property: "ochoPm",
          disabled: false,
          desc: '20'
        },
        {
          id: 21,
          name: "9:00 P.M",
          enabled: false,
          property: "nuevePm",
          disabled: false,
          desc: '21'
        },
        {
          id: 22,
          name: "10:00 P.M",
          enabled: false,
          property: "diezPm",
          disabled: false,
          desc: '22'
        },
        {
          id: 23,
          name: "11:00 P.M",
          enabled: false,
          property: "oncePm",
          disabled: false,
          desc: '23'
        },
        {
          id: 24,
          name: "12:00 A.M",
          enabled: false,
          property: "doceAm",
          disabled: false,
          desc: '24'
        },
      ]);

    const [resumenDiario, setResumenDiario] = useState([]);
    const resumenDiarioURL = endpoints.resumenDiario;

    useEffect(() => {
        let fecha = moment(new Date()).format("yyyy-MM-DD");

        requestResumenDiario(fecha);
        setTimeout(() => {
            setIsLoading(false);
            // scrollResumen();
        }, 1000);

        
    }, []);

    const scrollResumen = () => {
        let fecha = moment(new Date()).format("HH");
        var rect = document.querySelector(`.h-${fecha}`).getBoundingClientRect();
        document.querySelector('.table-responsive').scroll(rect.x, 0);
        
    }

    useEffect(() => {
        let totalPorcentaje = [];
        let generado = {};
        let hidroelectrica={} ;
        let solar={};
        let eolica={};
        let termico = {};
        let demandaReal = {};
        let potenciaNoServida = {};

        const generarDespacho = async () => {

            if(Object.keys(resumenDiario).length > 0 && resumenDiario.data !== null) {
                resumenDiario.data.forEach((resumen) => {
                    
                    if(resumen.totales === "Total Generado"){
                        setTotalGenerado(resumen);
                        generado = resumen
                    }
                    if(resumen.totales === "Total Programado"){
                        setTotalProgramado(resumen);
                    }
                    if(resumen.totales === "Total Térmico"){
                        setTotalTermico(resumen);
                        termico = resumen;
                    }
                    if(resumen.totales === "Total Eólico"){
                        setTotalEolico(resumen)
                        eolica = resumen;
                    }
                    if(resumen.totales === "Total Hidroeléctrica"){
                        setTotalHidroelectrica(resumen)
                        hidroelectrica = resumen;
                    }
                    if(resumen.totales === "Total Solar"){
                        setTotalSolar(resumen);
                        solar = resumen;
                    }
                    if(resumen.totales === 'Demanda Real') {
                        setDemandaReal(resumen);
                        demandaReal = resumen;
                    }
                    totalPorcentaje[0] = numberFormat((calcularPorcentajeSinFiltro(termico.seisAm,generado.seisAm)+calcularPorcentajeSinFiltro(eolica.seisAm,generado.seisAm)+calcularPorcentajeSinFiltro(hidroelectrica.seisAm,generado.seisAm)+calcularPorcentajeSinFiltro(solar.seisAm,generado.seisAm)),0);
                    totalPorcentaje[1] = numberFormat((calcularPorcentajeSinFiltro(termico.sieteAm,generado.sieteAm)+calcularPorcentajeSinFiltro(eolica.sieteAm,generado.sieteAm)+calcularPorcentajeSinFiltro(hidroelectrica.sieteAm,generado.sieteAm)+calcularPorcentajeSinFiltro(solar.sieteAm,generado.sieteAm)),0);
                    totalPorcentaje[2] = numberFormat((calcularPorcentajeSinFiltro(termico.ochoAm,generado.ochoAm)+calcularPorcentajeSinFiltro(eolica.ochoAm,generado.ochoAm)+calcularPorcentajeSinFiltro(hidroelectrica.ochoAm,generado.ochoAm)+calcularPorcentajeSinFiltro(solar.ochoAm,generado.ochoAm)),0);
                    totalPorcentaje[3] = numberFormat((calcularPorcentajeSinFiltro(termico.nueveAm,generado.nueveAm)+calcularPorcentajeSinFiltro(eolica.nueveAm,generado.nueveAm)+calcularPorcentajeSinFiltro(hidroelectrica.nueveAm,generado.nueveAm)+calcularPorcentajeSinFiltro(solar.nueveAm,generado.nueveAm)),0);
                    totalPorcentaje[4] = numberFormat((calcularPorcentajeSinFiltro(termico.diezAm,generado.diezAm)+calcularPorcentajeSinFiltro(eolica.diezAm,generado.diezAm)+calcularPorcentajeSinFiltro(hidroelectrica.diezAm,generado.diezAm)+calcularPorcentajeSinFiltro(solar.diezAm,generado.diezAm)),0);
                    totalPorcentaje[5] = numberFormat((calcularPorcentajeSinFiltro(termico.onceAm,generado.onceAm)+calcularPorcentajeSinFiltro(eolica.onceAm,generado.onceAm)+calcularPorcentajeSinFiltro(hidroelectrica.onceAm,generado.onceAm)+calcularPorcentajeSinFiltro(solar.onceAm,generado.onceAm)),0);
                    totalPorcentaje[6] = numberFormat((calcularPorcentajeSinFiltro(termico.doceAm,generado.doceAm)+calcularPorcentajeSinFiltro(eolica.doceAm,generado.doceAm)+calcularPorcentajeSinFiltro(hidroelectrica.doceAm,generado.doceAm)+calcularPorcentajeSinFiltro(solar.doceAm,generado.doceAm)),0);
                    totalPorcentaje[7] = numberFormat((calcularPorcentajeSinFiltro(termico.unaPm,generado.unaPm)+calcularPorcentajeSinFiltro(eolica.unaPm,generado.unaPm)+calcularPorcentajeSinFiltro(hidroelectrica.unaPm,generado.unaPm)+calcularPorcentajeSinFiltro(solar.unaPm,generado.unaPm)),0);
                    totalPorcentaje[8] = numberFormat((calcularPorcentajeSinFiltro(termico.dosPm,generado.dosPm)+calcularPorcentajeSinFiltro(eolica.dosPm,generado.dosPm)+calcularPorcentajeSinFiltro(hidroelectrica.dosPm,generado.dosPm)+calcularPorcentajeSinFiltro(solar.dosPm,generado.dosPm)),0);
                    totalPorcentaje[9] = numberFormat((calcularPorcentajeSinFiltro(termico.tresPm,generado.tresPm)+calcularPorcentajeSinFiltro(eolica.tresPm,generado.tresPm)+calcularPorcentajeSinFiltro(hidroelectrica.tresPm,generado.tresPm)+calcularPorcentajeSinFiltro(solar.tresPm,generado.tresPm)),0);
                    totalPorcentaje[10] = numberFormat((calcularPorcentajeSinFiltro(termico.cuatroPm,generado.cuatroPm)+calcularPorcentajeSinFiltro(eolica.cuatroPm,generado.cuatroPm)+calcularPorcentajeSinFiltro(hidroelectrica.cuatroPm,generado.cuatroPm)+calcularPorcentajeSinFiltro(solar.cuatroPm,generado.cuatroPm)),0);
                    totalPorcentaje[11] = numberFormat((calcularPorcentajeSinFiltro(termico.cincoPm,generado.cincoPm)+calcularPorcentajeSinFiltro(eolica.cincoPm,generado.cincoPm)+calcularPorcentajeSinFiltro(hidroelectrica.cincoPm,generado.cincoPm)+calcularPorcentajeSinFiltro(solar.cincoPm,generado.cincoPm)),0);
                    totalPorcentaje[12] = numberFormat((calcularPorcentajeSinFiltro(termico.seisPm,generado.seisPm)+calcularPorcentajeSinFiltro(eolica.seisPm,generado.seisPm)+calcularPorcentajeSinFiltro(hidroelectrica.seisPm,generado.seisPm)+calcularPorcentajeSinFiltro(solar.seisPm,generado.seisPm)),0);
                    totalPorcentaje[13] = numberFormat((calcularPorcentajeSinFiltro(termico.sietePm,generado.sietePm)+calcularPorcentajeSinFiltro(eolica.sietePm,generado.sietePm)+calcularPorcentajeSinFiltro(hidroelectrica.sietePm,generado.sietePm)+calcularPorcentajeSinFiltro(solar.sietePm,generado.sietePm)),0);
                    totalPorcentaje[14] = numberFormat((calcularPorcentajeSinFiltro(termico.ochoPm,generado.ochoPm)+calcularPorcentajeSinFiltro(eolica.ochoPm,generado.ochoPm)+calcularPorcentajeSinFiltro(hidroelectrica.ochoPm,generado.ochoPm)+calcularPorcentajeSinFiltro(solar.ochoPm,generado.ochoPm)),0);
                    totalPorcentaje[15] = numberFormat((calcularPorcentajeSinFiltro(termico.nuevePm,generado.nuevePm)+calcularPorcentajeSinFiltro(eolica.nuevePm,generado.nuevePm)+calcularPorcentajeSinFiltro(hidroelectrica.nuevePm,generado.nuevePm)+calcularPorcentajeSinFiltro(solar.nuevePm,generado.nuevePm)),0);
                    totalPorcentaje[16] = numberFormat((calcularPorcentajeSinFiltro(termico.diezPm,generado.diezPm)+calcularPorcentajeSinFiltro(eolica.diezPm,generado.diezPm)+calcularPorcentajeSinFiltro(hidroelectrica.diezPm,generado.diezPm)+calcularPorcentajeSinFiltro(solar.diezPm,generado.diezPm)),0);
                    totalPorcentaje[17] = numberFormat((calcularPorcentajeSinFiltro(termico.oncePm,generado.oncePm)+calcularPorcentajeSinFiltro(eolica.oncePm,generado.oncePm)+calcularPorcentajeSinFiltro(hidroelectrica.oncePm,generado.oncePm)+calcularPorcentajeSinFiltro(solar.oncePm,generado.oncePm)),0);
                    totalPorcentaje[18] = numberFormat((calcularPorcentajeSinFiltro(termico.doceAm,generado.doceAm)+calcularPorcentajeSinFiltro(eolica.doceAm,generado.doceAm)+calcularPorcentajeSinFiltro(hidroelectrica.doceAm,generado.doceAm)+calcularPorcentajeSinFiltro(solar.doceAm,generado.doceAm)),0);
                    setTotalPorcentajeConsumido(totalPorcentaje);
                    
                });
            }
        }

        generarDespacho();
        setIsLoading(false);

        menuHoras.map( hora => {
            potenciaNoServida[hora.property] = ( demandaReal[hora.property] - generado[hora.property] );
        });

        potenciaNoServida['totales'] = 'Potencia No Servida';

        setPotenciaNoServida(potenciaNoServida);

    },[resumenDiario]);

    function calcularPorcentajeSinFiltro($numero1, $numero2){
        if($numero1 === 0 && $numero2 === 0)
            return 0;
        return ($numero1 / $numero2) * 100;
    }

    const requestResumenDiario = async (fecha) => {
        let fechaDefault = moment(fecha).format("yyyy-MM-DD");
        setDateSearch(fechaDefault);
        try {
            await axios.get(`${resumenDiarioURL}?fecha=${fecha}`, { headers: { "Access-Control-Allow-Origin": "*", 'Content-Type': "application/json" } })
            .then((rep) => {
                setResumenDiario(rep.data);
                console.log('request resumen diario ', rep.data);

            }).catch(async (error) => {
               console.log(error);
               document.getElementById("resumenDiario").innerHTML = "<h4 class='text-center'>Ha ocurrido un error al tratar de cargar los datos.</h4>";
            });
        } catch (error) {
            console.log(error)
        }
    }

    /*fecha*/
    const handleChange = (event) => {
        setDateSearch(event.target.value);
    }
    
    const handleSubmit = (event) => {
        event.preventDefault();

        let fecha = moment(dateSearch).format("yyyy-MM-DD");
        requestResumenDiario(fecha);
    }

    return (
        <div className="row">
            <div className="col-lg-12">
                
                <h3 className="title-chart text-center">{props.title}</h3>

                <div className="d-flex flex-row">
                    <div className="p-3">
                        <form method="post">
                            <div className="form-group">
                                <input type="date" className="form-control" name="fechaSelect" id="fechaSelect" value={dateSearch} onChange={ (e) => { e.preventDefault() ; handleChange(e)} } />
                               
                            </div>
                        </form>
                    </div>
                    <div className="p-2">
                        <button type="submit" className="btn btn-third-color mt-2 rounded-0" onClick={ (e) => { e.preventDefault(); handleSubmit(e) } }>Enviar</button>
                    </div>
                </div>
                
                {
                    isLoading ? 
                        <div className="text-center">
                            <Loading />
                        </div>
                    :
                        <div className="col-lg-12" id="resumenDiario">
                        
                        { Object.keys(resumenDiario).length  && resumenDiario.data !== null 
                            ? 
                            <div className="table-responsive">
                                <table className="table table-bordered mb-4 bg-white">
                                    <tbody>
                                        <tr>
                                            <td className="bg-danger text-white text-center font-weight-bold">CORTE: {moment(new Date()).format("hh:mm A")}.</td>
                                            <td className="text-white text-center font-weight-bold custom-blue" colSpan="24">PERÍODO DE TIEMPO</td>
                                        </tr>
                                        <tr className="font-weight-bold" style={{ backgroundColor: '#AEAAAA' }}>
                                            <td rowSpan="3" className="font-weight-bold text-white">DESCRIPCIÓN</td>
                                        </tr>
                                        <tr  className="font-weight-bold text-white" style={{ backgroundColor: '#AEAAAA' }}>
                                            {
                                                menuHoras.map( hora => {
                                                    return (
                                                        <td>{hora.name}</td>
                                                    )
                                                })
                                            }
                                        </tr>
                                        <tr className="font-weight-bold text-white" style={{ backgroundColor: '#AEAAAA' }}>
                                            {
                                                menuHoras.map( hora => {
                                                    return (
                                                        <td class={`h-${hora.desc}`}>{hora.id}</td>
                                                    )
                                                })
                                            }
                                        </tr>
                                        {Object.keys(resumenDiario).length > 0 &&(resumenDiario.data.map(resumen => (
                                            <tr key={resumen.numero} style={ resumen.totales === 'Total Generado' ? { color: colors.azulClaro,  fontWeight: 'bold' } 
                                            : (( resumen.totales === 'Total Programado') ? { color: colors.verdeOscuro , fontWeight: 'bold'} 
                                            :  ((resumen.totales === 'Demanda Real') ? { color: colors.rojo ,  fontWeight: 'bold'} : {})  ) 
                                              }>
                                                <td >{resumen.totales}</td>

                                               {    menuHoras.map(hora => {
                                                       return (
                                                        <td>
                                                            {resumen[hora.property] > 0
            
                                                            ? numberFormat(resumen[hora.property])
                                                                    
                                                            : "-"}
                                                        </td>
                                                       ) 
                                                    }) 
                                                }   

                                                                                           
                                            </tr>
                                        )))}

                                        {           
                                            <tr> 
                                                <td>{potenciaNoServida.totales}</td>
                                                {    menuHoras.map(hora => {
                                                       return (
                                                        <td>
                                                            {potenciaNoServida[hora.property] > 0
            
                                                            ? numberFormat(potenciaNoServida[hora.property])
                                                                    
                                                            : "-"}
                                                        </td>
                                                       ) 
                                                    }) 
                                                } 
                                                
                                            </tr>
                                        }       
                                    </tbody>
                                </table>
                            </div>
                            : 
                            <div className="alert alert-warning" role="alert">
                                No hay datos para esta fecha 
                            </div>
                                                    
                        }
                        
                    </div>
                }

          
            </div>
        </div>
    )
}