import { AbastecimientoCombustible } from "../components/sidebar/GeneracionElectricidad/AbastecimientoCombustible"
import { AbastecimientoXGrupos } from "../components/sidebar/GeneracionElectricidad/AbastecimientoXGrupos"
// import { DisponibilidadGeneracionDiaria } from "../components/sidebar/GeneracionElectricidad/DisponibilidadGeneracionDiaria"
import { GeneracionTipoTecnologia } from "../components/sidebar/GeneracionElectricidad/GeneracionTipoTecnologia"
import { ResumenDiario } from "../components/sidebar/GeneracionElectricidad/ResumenDiario"

export const Inicio = () => {
    return (
        <div className="col-12 col-md-8 col-lg-9">
            <article className="content-servicios main-content">

                <ResumenDiario title="Resumen de generación eléctrica" />
                {/* <header className="article-header mt-5">
                    <h1 className="entry-title text-center">RESUMEN DE GENERACIÓN ELÉCTRICA</h1>
                </header> 

                <div className="row mt-3">
                    <div className="col-12">
                        <div id="carouselExampleControls" className="carousel slide" data-ride="carousel">
                            <div className="carousel-inner">
                            <div className="carousel-item active">
                                <img className="d-block w-100" src= {process.env.PUBLIC_URL + '/img/graficos/generacion-electrica.svg'} alt="First slide" />
                            </div> 
                            </div>
                            <a className="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span className="sr-only">Anterior</span>
                            </a>
                            <a className="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                            <span className="sr-only">Siguiente</span>
                            </a>
                        </div>
                    </div>
                </div> */}

                <div className="row">
                    
                    <div className="col-12 col-md-6 col-lg-12">
                         <AbastecimientoCombustible />
                    </div>
                    <div className="col-12 col-md-6 col-lg-12 mt-3">
                        <GeneracionTipoTecnologia />
                    </div>
                    
                </div>

                <div className="row">
                    <div className="col-12 col-md-6 col-lg-12 text-center mt-3">
                       <AbastecimientoXGrupos />
                    </div>
                </div>

                <hr style={{ height: '1.3rem' ,  backgroundColor:'#ee2a24'}} />

                {/* <div className="col-12 mt-3">
                    <div id="carouselExampleControls" className="carousel slide" data-ride="carousel">
                        <div className="carousel-inner">
                        <div className="carousel-item active">
                            <img className="d-block w-100" src={process.env.PUBLIC_URL + '/img/punta-catalina.png'} alt="First slide" />
                        </div>
                        </div>
                        <a className="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span className="sr-only">Previous</span>
                        </a>
                        <a className="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                        <span className="sr-only">Next</span>
                        </a>
                    </div>
                </div> */}

            </article>


        </div>
    )
}