import { Header } from '../components/Header';
import { Sidebar } from '../components/Sidebar';
import { Menu } from '../components/Menu';
import { Footer } from '../components/Footer';
import { FooterLogos } from '../components/FooterLogos';

import { Switch , Route } from 'react-router-dom'; 
import routes from "../routes/routes";
import routesNavbar from "../routes/routes-main-menu";

export const Page = ()  => {

    const getRoutes = (routes) => {
        return routes.map((prop, key) => {
          if (prop.layout === "/pag") {
            return (
              <Route
                path={prop.layout + prop.path}
                render={(props) => <prop.component {...props} />}
                key={key}
              />
            );
          } else {
            return null;
          }
        });
    };

    return (
        <div>
            <Header />
            
            <Menu routes={routesNavbar}/>

            <div className="container" id="content">
              <div className="row">
                <Sidebar routes={routes} />
                <Switch>{getRoutes(routes)}</Switch>
                <Switch>{getRoutes(routesNavbar)}</Switch>
              </div>
            </div>

            {/* <FooterLogos /> */}
            <Footer />
        </div>
    )
    
}