export const SobreNosotros = () => {
    return (
        <div className="col-12 col-md-8 col-lg-9">
            <article className="content-servicios main-content">
                <header className="article-header">
                    <ol className="breadcrumb rounded-0">
                        <li>
                            <span property="itemListElement" typeof="ListItem">
                                <a property="item" typeof="WebPage" title="Datos Sector Energía" href="index.php" className="home" >
                                    <span property="name">Inicio: </span>
                                </a>
                                <meta property="position" content="1" />
                            </span> /

                            <span property="itemListElement" typeof="ListItem">
                                <span property="name">Sobre nosotros</span>
                                <meta property="position" content="3" />
                            </span>
                        </li>
                    </ol>
                </header>
            </article>

            {/* <header className="article-header">
                <h1 className="entry-title">¿Sobre nosotros?</h1>
            </header> */}

            <div className="pf-content">
                <h3>¿Qué es el Ministerio de Energía y Minas?</h3>
                <p>
                    El Ministerio de Energía y Minas, es una dependencia de la&nbsp;
                    <a href="http://presidencia.gob.do/" target="_blank" rel="noopener noreferrer">
                        Presidencia de la República Dominicana
                    </a>,
                    organismo creado mediante la Ley 100-13, en el año 2013, con la finalidad de ser el órgano de la
                    Administración Pública dependiente del Poder Ejecutivo, encargado de la formulación y administración de la
                    política energética y de minería metálica y no metálica nacional. Corresponde al Ministerio de Energía y
                    Minas, en su calidad de órgano rector del sistema, la formulación, adopción, seguimiento, evaluación y
                    control de las políticas, estrategias, planes generales, programas, proyectos y servicios relativos al
                    sector energético y sus subsectores de energía eléctrica, energía renovable, energía nuclear, gas natural y
                    minería.
                </p>
                <p>Las funciones principales que desempeña el Ministerio de Energía y Minas son:</p>
                <ul>
                    <li>Promover e impulsar el desarrollo y sostenibilidad del sector de hidrocarburos.</li>
                    <li>Promover la Eficiencia y ahorro energético.</li>
                    <li>Desarrollar y mantener una infraestructura energética critica de forma segura y adecuada.</li>
                    <li>Regular y promover la producción y fomento del desarrollo de energía renovable y/o alternativas.</li>
                    <li>Regular, desarrollar y promover aplicaciones de Energía Nuclear para uso civil.</li>
                    <li>Regular, fiscalizar y promover el crecimiento del sector minero y su aporte al desarrollo del país, para
                        alcanzar una minería responsable, transparente y segura.</li>
                    <li>Crear y desarrollar las capacidades para el mejoramiento sostenible de la gestión institucional, para
                        lograr de forma efectiva la misión y visión del Ministerio de Energía y Minas</li>
                </ul>
                <div>
                    <h3>Visión</h3>
                    <div>
                        <div>
                            <p>
                                Ser una entidad de excelencia en la formulación y ejecución eficiente, responsable y transparente
                                de políticas de desarrollo, para el aprovechamiento integral y gestión sostenible de los recursos
                                energéticos y mineros, en beneficio de las presentes y futuras&nbsp;generaciones de dominicanos.
                            </p>
                        </div>

                        <div>
                            <h3>Misión</h3>
                            <p>
                                Formular y administrar políticas para el aprovechamiento integral de los recursos energéticos y
                                mineros de la República Dominicana, bajo criterios de transparencia y&nbsp;sostenibilidad
                                ambiental, económica y social.
                            </p>
                            <div>
                                <h3>Valores</h3>
                                <ul>
                                    <li>
                                        LEALTAD AL INTERÉS NACIONAL. Hacer prevalecer los intereses de la Nación por encima de los
                                        particulares, en el ejercicio de sus funciones.
                                    </li>
                                    <li>
                                        INTEGRIDAD. Actuar con ética, rectitud, transparencia, prudencia y honestidad, siendo coherentes en el
                                        cumplimiento de las políticas, leyes, normas y procedimientos.
                                    </li>
                                    <li>
                                        EXCELENCIA. Actuar en todo momento de manera eficiente, eficaz, transparente, con calidad y vocación
                                        de servicio, respetando las normas internas de la Administración Pública para contribuir al éxito de la
                                        Misión y Visión Institucionales.
                                    </li>
                                    <li>
                                        LIDERAZGO. Capacidad para orientar con alta eficiencia y eficacia sobre la ruta trazada en cuanto a la
                                        formulación, administración y ejecución de políticas de desarrollo, directrices, regulaciones y
                                        proyectos en el ámbito energético-minero de conformidad.
                                    </li>
                                    <li>COMPROMISO CON EL DESARROLLO.</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}