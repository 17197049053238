import React from 'react';
import { useLocation } from 'react-router-dom';
import downArrow from './../assets/img/downArrow.svg';

export const Sidebar = ( {routes} ) => {
    const location = useLocation();
    const activeRoute = (routeName) => {
        return location.pathname.indexOf(routeName) > -1 ? "rv-submenu-item submenu-item-current" : "";
    };

    return (
        <div className="d-none d-md-block col-md-4 col-lg-3">
            <script type="text/javascript">document.body.className += " with-submenu-rv"; </script>
            <div className="alert alert-principal text-center text-white rounded-0 mb-1" role="alert" style={{ fontWeight: 'bold' }}>
                DATOS ENERGÍA
            </div>
            <div className="text-center">
                <img className="down-arrow-custom" src={downArrow} alt="down arrow" />
            </div>

            <nav id="submenu">
                <ul id="submenu-2" className="submenu-rv">

                    {routes.map((prop, key) => {
                        
                        return (
                           
                            <>
                            {
                                prop.url ?

                                    <li
                                        className={
                                            activeRoute(prop.layout + prop.path) + ' p-2' 
                                        }
                                        key={key}
                                    >
                                        <a target="_blank" href={prop.url} rel="noreferrer">
                                            {prop.name} 
                                            <i className={ prop.icon}></i>
                                        </a>
                                    </li>
                            : 
                            prop.father  === 0 ?
                                <li
                                    className={
                                        activeRoute(prop.layout + prop.path) + ' p-2' 
                                    }
                                    key={key}
                                >   
                                    
                                    <a
                                        // to={ (prop.id) ?  `#collapse${prop.id}` :  prop.layout + prop.path}
                                        className="nav-link"
                                        activeclassname="rv-submenu-item submenu-item-current"
                                        data-toggle={(prop.id) ?  `collapse` : ""} href= { (prop.id) ?  `#collapse${prop.id}` : prop.layout + prop.path} role="button" aria-expanded="false" aria-controls={ (prop.id) ? `collapse${prop.id}` : prop.layout + prop.path}
                                    >
                                       <div className="d-flex flex-row">
                                            <div><i className={ prop.icon}></i></div>
                                            <div>{prop.name} </div>
                                            <div><i className={ (prop.id) ?  `fas fa-chevron-down float-right p-2` : "" }></i></div>
                                        </div>
                                        {prop.desc ? <br/> : null}
                                        {/* <span className="description-menu">{prop.desc}</span> */}
                                        
                                        
                                    </a>
                                </li>
                                :
                                <div className="collapse" id={`collapse${prop.father}`}>
                                    <li
                                        className={
                                            activeRoute(prop.layout + prop.path) + ' py-2 px-4' 
                                        }
                                        key={key}
                                    >
                                         <a role="button" aria-expanded="false" 
                                            className="nav-link"
                                            href={prop.layout + prop.path}
                                        >
                                           - {prop.name}
                                        </a>
                                    </li>
                                   
                                </div>
                            }
                            </>
                        );
                        return null;
                    })}
                </ul>
            </nav>
        </div>
        
    )
}