import React, { useEffect, useState } from "react";
import { colors } from "./../../theme/theme";
import { VictoryChart, VictoryBar } from "victory";
import { numberFormat } from "./../../utils/separador-miles";
// import {div,label,Dimensions,StyleSheet} from "react-native"
// import estilos from "../../assets/estilos";
const estilos = {
  divCharts: {
    marginTop: 10,
    paddingTop:10,
  
  },
};

function Barchart({
  arrayData,
  headerChart,
  minValueY,
  maxValueY,
  styleFunctionFill = {},
  symbol = "",
}) {
  // console.log("arrayData desde el grafico", arrayData);
  // console.log("min",Math.round(minValueY));
  // console.log("max",Math.round(maxValueY));
  return (
    arrayData.length > 0 && (
      <div style={estilos.divCharts}>
        <div className="row" style={{ textAlign: "center"}}>
          <div className="col-12 ">
            <h5 style={{ fontWeight: "bold" }}>{headerChart.title}</h5>
          </div>
          <div className="col-12 ">
            {headerChart.entidad != "" && <h6>{headerChart.entidad}</h6>}
          </div>
          <div className="col-12 ">
            <h6>{headerChart.unidadMedida}</h6>
          </div>
        </div>
        <div style={{paddingBottom:30, backgroundColor: colors.azulFondoGraficos, margin: 15 }}>
          <VictoryChart
            minDomain={{ y: Math.round(minValueY - minValueY * 0.05) }}
            //  minDomain={{y:100}}
            maxDomain={{ y: Math.round(maxValueY * 1.1) }}
            // theme={VictoryTheme.material}
            // width={Dimensions.get("window").width-50}
            domainPadding={{ x: 35 }}
            // domainPadding={{ x: 100 }}
            padding={{ left: 80, right: 5, top: 50, bottom: 50 }}
            //  height={400}
              // width={200}
            style={{
              labels: {
                fontSize: "10",
              },
            }}
            // animate={{duration: 500}}
            // containerComponent={
            //     <VictoryZoomContainer/>
            // }
          >
            <VictoryBar
              //  barWidth={({ index }) => index * 2 + 8}
              style={{ data: { fill: "#c43a31" } }}
              // barRatio={0.9}
              labels={({ datum }) => `${numberFormat(datum.y)}${symbol}`}
              // colorScale={[colors.azulOscuro, colors.verde, colors.rojo, colors.azulClaro, colors.marron, "tomato", "cyan", colors.azulTabla, colors.azulClaro]}
              // style={{
              //   data: {
              //     fill: styleFunctionFill
              //   },
              // }}
              data={arrayData}
            />
          </VictoryChart>
        </div>
      </div>
    )
  );
}

export default Barchart;
