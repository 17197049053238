import { useState, useEffect } from 'react';
import {VictoryPie,VictoryLegend} from 'victory';
import moment from "moment";
import {colors} from "../../../theme/theme";
import {numberFormat} from "../../../utils/separador-miles";
import { endpoints } from "../../../config";
import axios from 'axios';
// import { AbastecimientoXCentrales } from './AbastecimientoXCentrales';
import { Loading } from '../../Loading';
import { CustomLabel } from './../CustomLabel';

export const AbastecimientoCombustible = () => {

    const [isLoading, setIsLoading] = useState(true);
    const [produccionEnergeticaFp, setProduccionEnergeticaFp] = useState([]);
    const [abastecimientoCentrales, setAbastecimientoCentrales] = useState([]);
    const produccionEnergeticaFpURL = endpoints.produccionEnergeticaFp;

    useEffect(()=>{
        let fecha = moment(new Date()).format("yyyy-MM-DD");
        generarAbastecimientoCombustible(fecha);
        setTimeout(() => {
            setIsLoading(false);
        }, 500);
       
    },[]);

    useEffect(()=> { 
        if(Object.keys(produccionEnergeticaFp).length > 0){
            generarAbastecimientoCentrales();
        }
    },[produccionEnergeticaFp]);

    const generarAbastecimientoCombustible = async (fecha) => {

        let abastecimientoCombustibleList = [];
        await axios.get(`${produccionEnergeticaFpURL}?fecha=${fecha}`, { headers: { "Access-Control-Allow-Origin": "*", 'Content-Type': "application/json" } })
       
        .then(rep => {
            Object.entries(rep.data["data"]).map(produccionEnergetica => {
                if(produccionEnergetica[1].promedioPorCombustible > 0){
                    abastecimientoCombustibleList = [...abastecimientoCombustibleList, {
                        name:`${produccionEnergetica[1].combustible}: ${numberFormat(produccionEnergetica[1].promedioPorCombustible)}%`,
                        x: produccionEnergetica[1].combustible,
                        y: produccionEnergetica[1].promedioPorCombustible,
                        placement: `${produccionEnergetica[1].combustible}: ${numberFormat(produccionEnergetica[1].promedioPorCombustible)}% `,
                        combustible: produccionEnergetica[1].combustible,
                        promedioPorCombustible : produccionEnergetica[1].promedioPorCombustible
                    } ]
                }

                return true;
            })

        }).catch(err => {
            console.log(err);
            document.getElementById("AbastecimientoCombustible").innerHTML = "<h4 class='text-center'>Ha ocurrido un error al tratar de cargar los datos.</h4>";
        });
       
        setProduccionEnergeticaFp(abastecimientoCombustibleList);  
    }

    const generarAbastecimientoCentrales = () =>{
        let abastecimientoCentralesList = [];
        let termico = 0;
        let hidroElectrica = 0;
        let renovable = 0;
        

        if(produccionEnergeticaFp){
            produccionEnergeticaFp.forEach((produccionEnergetica) => {
                
                if(produccionEnergetica.combustible === "Solar" || produccionEnergetica.combustible === "Eólico" ||  produccionEnergetica.combustible === "Biomasa")
                {
                    renovable += produccionEnergetica.promedioPorCombustible
                    // produccionEnergeticaList = [...produccionEnergeticaList, {
                    //     x: produccionEnergetica.combustible,
                    //     y: produccionEnergetica.promedioPorCombustible,
                    //     placement: `${produccionEnergetica.combustible}: ${numberFormat(produccionEnergetica.promedioPorCombustible)}% `
                    // } ]
                }else if(produccionEnergetica.combustible ==="Carbón" || produccionEnergetica.combustible ==="Gas Natural" || produccionEnergetica.combustible ==="Fuel Oil No. 2" || produccionEnergetica.combustible ==="Fuel Oil No. 6" || produccionEnergetica.combustible ==="Fuel Oil No. 2 y No. 6"){
                    termico += produccionEnergetica.promedioPorCombustible
                }else{
                    hidroElectrica += produccionEnergetica.promedioPorCombustible
                }
            })
            if(termico > 0){
                abastecimientoCentralesList = [...abastecimientoCentralesList, {
                    x:"Térmico",
                    y: termico,
                    placement: `Termico: ${termico}% `
                } ]
            }
            if(hidroElectrica > 0){
                abastecimientoCentralesList = [...abastecimientoCentralesList, {
                    x:"Hidroeléctrica",
                    y: hidroElectrica,
                    placement: `Hidroelectrica: ${hidroElectrica}% `
                } ]
            }
            if(renovable > 0){
                abastecimientoCentralesList = [...abastecimientoCentralesList, {
                    x:"Regim. E.",
                    y: renovable,
                    placement: `Renovable: ${renovable}% `
                } ]
            }
            
            setAbastecimientoCentrales(abastecimientoCentralesList);  
        }

    }

    return (
        <div className="d-flex flex-column ">
            {
                isLoading ?
                    <Loading />
            :
                <>
                    <div className="p-1">
                        {/* <h2 className="mt-2 bg-white p-2">RESUMEN GRÁFICO DE ABASTECIMIENTO EN TIEMPO REAL ACUMULADO</h2> */}
                        <h3 className="mt-3 title-chart">Abastecimiento por Fuente Primaria</h3>

                            <div id="AbastecimientoCombustible">
                                <VictoryPie
                                    colorScale={[colors.azulOscuro, colors.verde, colors.rojo, colors.azulClaro,colors.marron,"tomato","cyan" ,"green"]} 
                                    innerRadius={70}
                                    style={{labels: { /*margin:30,padding: 5 ,*/ fontSize: 11, fontWeight: 'bold' }}}
                                    data={produccionEnergeticaFp.length > 0? produccionEnergeticaFp:[]}
                                    labels={({ datum }) =>  `${numberFormat(datum.y)}%`}
                                    // labels={({ datum }) =>datum.y > 5 ? `${numberFormat(datum.y)}%`:""}
                                    //  padAngle = {({ datum }) => datum.y}
                                    // padding={{ top: 50, bottom: 60 }}
                                    width={800}
                                    height={400}
                                    labelComponent={<CustomLabel data={produccionEnergeticaFp}  angle={90}/>}
                                    
                                />
                                <VictoryLegend 
                                    colorScale={[colors.azulOscuro, colors.verde, colors.rojo, colors.azulClaro,colors.marron,"tomato","cyan", "green"]} 
                                    centerTitle
                                    itemsPerRow={2}
                                    orientation="vertical"
                                    gutter={40}
                                    style={{ labels: { fontSize: 11, fontWeight: 'bold' }, parent: { margin: 0 } }}
                                    data={produccionEnergeticaFp}
                                    height={80}
                                    width={800}
                                    x={135}
                                    y={0}
                                />
                            </div>
                        
                    </div>

                    {/* <AbastecimientoXCentrales abastecimientoCentrales={abastecimientoCentrales} /> */}
                </>
            }
      
        </div>
    )
}