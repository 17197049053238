import { Costos } from "../components/sidebar/Costos";
import { DetallesGeneracion } from "../components/sidebar/DetallesGeneracion";
import { DistribuccionComercializacion } from "../components/sidebar/DistribuccionComercializacion";
import { Finanzas } from "../components/sidebar/Finanzas";
import { GeneracionElectricidad } from "../components/sidebar/GeneracionElectricidad";
import { InformesPreselecionados } from "../components/sidebar/InformesPreselecionados";
import { OtrasFuentesInteres } from "../components/sidebar/OtrasFuentesInteres";
import { Planificacion } from "../components/sidebar/Planificacion";
import { Transmision } from "../components/sidebar/Transmision";
import { IndicadoresMacroeconimicos } from "../components/sidebar/IndicadoresMacroeconimicos";

const sidebarRoutes = [
  {
    id: 1,
    path: "/generacion-electricidad",
    name: "GENERACIÓN ELECTRICIDAD",
    component: GeneracionElectricidad,
    layout: "/admin",
    icon: "fas fa-bolt fa-lg mr-2",
    father: 0
  },
  {
    path: "/generacion-electricidad",
    name: "GENERACIÓN",
    component: GeneracionElectricidad,
    layout: "/admin",
    icon: "fas fa-angle-right fa-lg mr-2",
    father: 1
  },
  {
    path: "/costos",
    name: "COSTOS",
    component: Costos,
    layout: "/admin",
    icon: "fas fa-dollar-sign fa-lg mr-2",
    father: 1
  },
  {
    path: "/planificacion",
    name: "PLANIFICACIÓN DEL SECTOR",
    component: Planificacion,
    layout: "/admin",
    icon: "fas fa-file-alt fa-lg mr-2",
    father: 1
  },
  {
    path: "/transmision",
    name: "TRANSMISIÓN",
    component: Transmision,
    layout: "/admin",
    icon: "fas fa-file-import fa-lg mr-2",
    father: 0
  },
  // {
  //   path: "/detalles-generacion",
  //   name: "DETALLES GENERACIÓN",
  //   component: DetallesGeneracion,
  //   layout: "/admin",
  //   desc: "CENTRALES/FUENTES",
  //   father: 0
  // },

  {
    path: "/distribuccion-y-comercializacion",
    name: "DISTRIBUCIÓN  Y COMERCIALIZACIÓN",
    component: DistribuccionComercializacion,
    layout: "/admin",
    desc: "(electricidad)",
    icon: "mr-3 fas fa-exchange-alt fa-lg",
    father: 0
  },
  {
    path: "/finanzas",
    name: "FINANZAS",
    component: Finanzas,
    layout: "/admin",
    icon: "mr-3 fas fa-wallet fa-lg",
    father: 0
  },
  {
    path: "/indicadores-macroeconomicos",
    name: "INDICADORES MACROECONÓMICOS",
    component: IndicadoresMacroeconimicos,
    layout: "/admin",
    icon: "mr-3 fas fa-coins fa-lg",
    father: 0
  },
  // {
  //   url: "https://mem.gob.do/transparencia/datos-abiertos/",
  //   name: "DATOS ABIERTOS",
  //   layout: "/admin",
  //   icon: "fas fa-comments fa-lg mr-2",
  //   father: 0
  // },
  {
    path: "/informes-preseleccionados",
    name: "INFORMES",
    component: InformesPreselecionados,
    layout: "/admin",
    icon: "fas fa-folder fa-lg mr-2",
    father: 0
  },
  // {
  //   path: "/otras-fuentes-interes",
  //   name: "OTRAS FUENTES DE INTERÉS",
  //   component: OtrasFuentesInteres,
  //   layout: "/admin",
  //   icon: "fas fa-paperclip fa-lg mr-2",
  //   father: 0
  // },

];

export default sidebarRoutes;