import { Contacto } from "../page/Contacto";
import { Inicio } from "../page/Inicio";
import { MapaDeSitio } from "../page/MapaDeSitio";
// import { SalaInformativa } from "../page/SalaInformativa";
import { SobreNosotros } from "../page/SobreNosotros";

const navbarRoutes = [
  {
    path: "/inicio",
    name: "INICIO",
    component: Inicio,
    layout: "/pag",
    class: 'inicio'
  },
  {
    path: "/sobre-nosotros",
    name: "SOBRE NOSOTROS",
    component: SobreNosotros,
    layout: "/pag",
  },
  /*{
    path: "/sala-informativa",
    name: "SALA INFORMATIVA",
    component: SalaInformativa,
    layout: "/pag",
  },*/
  {
    path: "/mapa-de-sitio",
    name: "Mapa de sitio",
    component: MapaDeSitio,
    layout: "/pag",
    menu_header: true
  },
  {
    path: "/contacto",
    name: "Contacto",
    component: Contacto,
    layout: "/pag",
    menu_header: true
  },
];
  
  export default navbarRoutes;