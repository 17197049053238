import React, { useEffect, useState } from "react";
import { colors } from "./../../theme/theme";
import {
  VictoryChart,
  VictoryContainer,
  VictoryLine,
  VictoryLabel,
  VictoryTheme,
  VictoryLegend
} from "victory";
import { numberFormat } from "./../../utils/separador-miles";
import moment from "moment";
// import { div, label, Dimensions,StyleSheet } from "react-native";
// import estilos from "../../assets/estilos";
const estilos = {
  divCharts:{
    marginTop:10,
    backgroundColor:colors.azulFondoGraficos,
    
  },
};

function LineChart({ dataLineChart, headerChart, maxValueY, minValueY }) {

    let colorScale=[colors.azulOscuro, colors.rojo, colors.azulClaro,colors.marron,"tomato","cyan" ,"green", colors.azulTabla, colors.marronPerla, colors.negro, colors.verdeOscuro, colors.lightYellow, colors.grisRaton, colors.lilac, colors.almond, colors.orangeSoda, colors.oldLavender, colors.blueBell, colors.champagePink, colors.verde]
    let arrayLeyend = [];
  const lineChartGeneration = (data) => {
      arrayLeyend=[...arrayLeyend,{
        name:data.indicador,
        symbol: { fill:colorScale[arrayLeyend.length] , type: "minus" }
    }]

    let lineChart = [];


    data.valoresIndicadoresCalculados.forEach((item) => {
      lineChart = [
        ...lineChart,
        {
          name: item.indicadorCalculadoId,
          x: `${moment(`${item.ano}/${item.mes}/12`, "yyyy/MM/DD").format(
            "MMM-YYYY"
          )}`,
          y: item.valor,
          placement: `${item.valor}`,
        },
      ];
    });

    return lineChart;
  };
  return (
    <div style={estilos.divCharts}>
      <div
        style={{
        textAlign:"center",
          marginTop: 10,
        }}
      >
        <h5 style={{fontWeight: "bold" }}>{headerChart.title}</h5>
        {headerChart.entidad != "" && <h6>{headerChart.entidad}</h6>}
        <h6>{headerChart.unidadMedida}</h6>
      </div>
      <div style={{ paddingBottom:30, backgroundColor: colors.azulFondoGraficos, margin: 15 }}>
        <VictoryChart
          // minDomain={0}
          theme={VictoryTheme.material}
          //   padding={{ left: 90, right: 50, top: 30, bottom: 100 }}
          padding={{ left: 70, right: 40, top: 30, bottom: 100 }}
          domainPadding={{ x: 30 }}
          minDomain={{ y: minValueY - minValueY * 0.05 }}
          // minDomain={0}
          maxDomain={{ y: maxValueY * 1.1 }}
          // domain={{ y: [0, 500] }}
          // height={350}
          // width={350}
          // animate={{duration: 500}}
        >
          {dataLineChart.map((data,index) => {
            return (
              <VictoryLine
                key={
                  data.valoresIndicadoresCalculados.ano +
                  data.valoresIndicadoresCalculados.periodo
                }
                style={{
                  data: { stroke: colorScale[index] },
                  labels: {
                    fontSize: 11,
                    fontWeight:"bold",
                    fill: colorScale[index]
                  }
                  // parent: { border: `1px solid ${colorScale[index]}`}
                }}
                data={lineChartGeneration(data)}
                labels={({ datum }) =>
                  Math.round((datum.y + Number.EPSILON) * 100) / 100
                }
                // labelComponent={<VictoryLabel renderInPortal dy={-20}/>}
                // containerComponent={
                //     <VictoryContainer
                //       preserveAspectRatio="none"
                //       responsive={false}
                //       width={400}
                //       height={280}

                //     />
                //   }
              />
            );
          })}
        </VictoryChart>
      
      
       <div >
        {  arrayLeyend.length>1 ?
                      <VictoryLegend 
                          // colorScale={[colors.azulOscuro, colors.verde, colors.rojo, colors.azulClaro,colors.marron,"tomato","cyan" ,"green", colors.azulTabla, colors.marronPerla, colors.negro, colors.verdeOscuro, colors.lightYellow, colors.grisRaton, colors.lilac, colors.almond, colors.orangeSoda, colors.oldLavender, colors.blueBell, colors.champagePink]} 
                          x={5}
                          y={0}
                          height={100}
                          itemsPerRow={2}
                          // title="Leyenda"
                          centerTitle
                          orientation="horizontal"
                          gutter={20}
                          style={{ title: {fontSize: 10 } }}
                          data={arrayLeyend}
                          width={400}
                          // orientation="vertical"
                      />
                  :
                  null} 
                  
        </div>
               
      </div>
    </div>
  );
}

export default LineChart;
