import { useState, useEffect } from 'react';
import {VictoryBar, VictoryChart} from 'victory';
import moment from "moment";
import {colors} from "../../../theme/theme";
import {numberFormat} from "../../../utils/separador-miles";
import { endpoints } from "../../../config";
import axios from 'axios';
import { Loading } from '../../Loading';
import { CustomLabel } from './../CustomLabel';

export const AbastecimientoXGrupos = () => {

    const [isLoading, setIsLoading] = useState(true);
    const [produccionEnergeticaFp, setProduccionEnergeticaFp] = useState([]);
    const [abastecimientoCentrales, setAbastecimientoCentrales] = useState([]);
    const produccionEnergeticaFpURL = endpoints.produccionEnergeticaFp;

    useEffect(()=>{
      let fecha = moment(new Date()).format("yyyy-MM-DD");
      generarAbastecimientoCombustible(fecha);
      setTimeout(() => {
        setIsLoading(false);
      }, 500);
    },[]);


    useEffect(()=> { 
      if(Object.keys(produccionEnergeticaFp).length > 0){
          generarAbastecimientoCentrales();
      }
  },[produccionEnergeticaFp]);

    const generarAbastecimientoCombustible = async (fecha) => {

      let abastecimientoCombustibleList = [];
      await axios.get(`${produccionEnergeticaFpURL}?fecha=${fecha}`, { headers: { "Access-Control-Allow-Origin": "*", 'Content-Type': "application/json" } })
     
      .then(rep => {
          Object.entries(rep.data["data"]).map(produccionEnergetica => {
              if(produccionEnergetica[1].promedioPorCombustible > 0){
                  abastecimientoCombustibleList = [...abastecimientoCombustibleList, {
                      name:`${produccionEnergetica[1].combustible}: ${numberFormat(produccionEnergetica[1].promedioPorCombustible)}%`,
                      x: produccionEnergetica[1].combustible,
                      y: produccionEnergetica[1].promedioPorCombustible,
                      placement: `${produccionEnergetica[1].combustible}: ${numberFormat(produccionEnergetica[1].promedioPorCombustible)}% `,
                      combustible: produccionEnergetica[1].combustible,
                      promedioPorCombustible : produccionEnergetica[1].promedioPorCombustible
                  } ]
              }
              return true;
          })

      }).catch(err => {
          console.log(err);
      });
     
      setProduccionEnergeticaFp(abastecimientoCombustibleList);  
  }

  const generarAbastecimientoCentrales = () =>{
      let abastecimientoCentralesList = [];
      let termico = 0;
      let hidroElectrica = 0;
      let renovable = 0;
      

      if(produccionEnergeticaFp){
          produccionEnergeticaFp.forEach((produccionEnergetica) => {
              
              if(produccionEnergetica.combustible === "Solar" || produccionEnergetica.combustible === "Eólico" ||  produccionEnergetica.combustible === "Biomasa")
              {
                  renovable += produccionEnergetica.promedioPorCombustible
                  // produccionEnergeticaList = [...produccionEnergeticaList, {
                  //     x: produccionEnergetica.combustible,
                  //     y: produccionEnergetica.promedioPorCombustible,
                  //     placement: `${produccionEnergetica.combustible}: ${numberFormat(produccionEnergetica.promedioPorCombustible)}% `
                  // } ]
              }else if(produccionEnergetica.combustible ==="Carbón" || produccionEnergetica.combustible ==="Gas Natural" || produccionEnergetica.combustible ==="Fuel Oil No. 2" || produccionEnergetica.combustible ==="Fuel Oil No. 6" || produccionEnergetica.combustible ==="Fuel Oil No. 2 y No. 6"){
                  termico += produccionEnergetica.promedioPorCombustible
              }else{
                  hidroElectrica += produccionEnergetica.promedioPorCombustible
              }
          })
          if(termico > 0){
              abastecimientoCentralesList = [...abastecimientoCentralesList, {
                  x:"Térmico",
                  y: termico,
                  placement: `Termico: ${termico}% `
              } ]
          }
          if(hidroElectrica > 0){
              abastecimientoCentralesList = [...abastecimientoCentralesList, {
                  x:"Hidroeléctrica",
                  y: hidroElectrica,
                  placement: `Hidroelectrica: ${hidroElectrica}% `
              } ]
          }
          if(renovable > 0){
              abastecimientoCentralesList = [...abastecimientoCentralesList, {
                  x:"Regim. E.",
                  y: renovable,
                  placement: `Renovable: ${renovable}% `
              } ]
          }
          
          setAbastecimientoCentrales(abastecimientoCentralesList);  
      }

  }

    return(
        <div className="p-1">
            <h3 className="mt-3 title-chart">Abastecimiento por Grupos</h3>

            {
              isLoading ?
              <Loading />
              :
              <VictoryChart
                    // theme={VictoryTheme.material}
                    domainPadding={{ x: 45 }}
                    height={250} width={450}
                    style={{
                        labels: {
                            fontSize: '10'
                        }
                    }}
                >
                <VictoryBar
                    //  barWidth={({ index }) => index * 2 + 8}
                    barRatio={0.9}
                    labels={({ datum }) => `${numberFormat(datum.y) }%`}
                    
                    style={{
                        data: { fill: ({ datum }) => datum.x === "Térmico" ? colors.rojo :datum.x ==="Hidroeléctrica"? colors.azulClaro: colors.verdeOscuro,   },
                        labels: {  fontSize: '10', fontWeight: 'bold' }
                    }}
                    data={abastecimientoCentrales}
                    labelComponent={<CustomLabel data={abastecimientoCentrales}/>}

                    // events={[
                    //     {
                    //       target: "data",
                    //       eventHandlers: {
                    //         onMouseOver: () => {
                    //           return [{
                    //             target: "labels",
                    //             mutation: (props) => {
                    //               return props.datum.y === "clicked" ?
                    //                 null : { 
                    //                     text: 
                    //                       `${props.datum.x} :  ${numberFormat(props.datum.y) }%`

                    //                 }
                    //             }
                    //           }];
                    //         },
                    //         onMouseLeave: () => {
                    //             return [{
                    //               target: "labels",
                    //               mutation: (props) => {
                    //                 return props.datum.y === "clicked" ?
                    //                   null : { text: `${numberFormat(props.datum.y) }%` }
                    //               }
                    //             }];
                    //           }
                    //       }
                    //     }
                    //   ]}
                    
                />
                </VictoryChart>
            }
        </div>
    )
}