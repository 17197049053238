import { useState } from "react"

export const Contacto = () => {

    const [contacto, setContacto] = useState([]);

    const handleChange = (e) => {

        const {name, value} = e.target;

        document.getElementsByName(`${name}`)[0].classList.add("is-valid");
        document.getElementsByName(`${name}`)[0].classList.remove("is-invalid");

        setContacto({
            ...contacto,
            [name]: value
        });
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        if(contacto.nombre === "" || contacto.nombre === undefined) {
            document.getElementsByName('nombre')[0].classList.add("is-invalid");
        }

        if(contacto.correo === "" || contacto.correo === undefined){
            document.getElementsByName('correo')[0].classList.add("is-invalid");
        }
        
        if(contacto.mensaje === "" || contacto.mensaje === undefined){
            document.getElementsByName('mensaje')[0].classList.add("is-invalid");
        }

        // proceso para enviar correo
        if(contacto.nombre !== "" && contacto.nombre !== undefined && contacto.correo !== "" && contacto.correo !== undefined && contacto.mensaje  !== "" && contacto.mensaje !== undefined){
            console.log('se ha enviado un correo')
        }

    }

    return (
        <div className="col-12 col-md-8 col-lg-9">
             <article className="content-servicios main-content">
                <header className="article-header">
                    <ol className="breadcrumb rounded-0">
                        <li>
                            <span property="itemListElement" typeof="ListItem">
                                <a property="item" typeof="WebPage" title="Datos Sector Energía" href="/" className="home">
                                    <span property="name">Inicio: </span>
                                </a><meta property="position" content="1" /
                                ></span> /

                            <span property="itemListElement" typeof="ListItem">
                                <span property="name">Contacto</span>
                                <meta property="position" content="3" /></span>
                        </li>
                    </ol>
                </header>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-6 mt-3">
                            <h4 className="mb-3">¿DONDE ESTAMOS UBICADOS?</h4>
                            <strong>Dirección:</strong><br />
                            Ave. Tiradentes # 53, esquina Heriberto Pieter, Bloque B, Ensanche Naco, Santo Domingo, R.D. <br />
                            <strong>Teléfono:</strong> 809-373-1800 <br />
                            <strong>Fax:&nbsp;</strong>809-373-1800 <br />
                            <strong>Correo electrónico:&nbsp;</strong>info@mem.gob.do <br />
                            <strong>Horario Laboral:</strong><br />
                            Lunes a viernes de 8:00am a 4:00pm
                        </div>
                        <div className="col-lg-6 mt-3">
                            <form onSubmit={handleSubmit}>
                                <div className="form-group">
                                    <label>Nombre (*)</label>
                                    <input type="text" className="form-control" aria-describedby="nombre" placeholder="Nombre" name="nombre" onChange={handleChange}/>
                                    <div className="invalid-feedback">
                                       Complete el campo de nombre.
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label>Correo electrónico (*)</label>
                                    <input type="text" className="form-control" placeholder="correo" name="correo" onChange={handleChange}/>
                                    <div className="invalid-feedback">
                                       Complete el campo de correo.
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label>Mensaje</label>
                                    <textarea className="form-control" name="mensaje" onChange={handleChange}/>
                                    <div className="invalid-feedback">
                                       Complete el campo de mensaje.
                                    </div>
                                </div>
                                <button type="submit" className="btn btn-secondary rounded-0">Enviar</button>
                            </form>
                        </div>
                    </div>
                </div>
            </article>
            
        </div>
    )
}